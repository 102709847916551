import { FC } from "react"
import { Tooltip, Collapse, Box, TableBody } from "@mui/material"
import { TruncatedTypography } from "@windgis/shared"
import { useBuildProperties } from "components/map/infoBoxes/hooks/useBuildProperties"
import { BoatDetailsInfobox } from "features/ais/models/BoatDetailsInfobox"
import {
    CollapsedExpandLess,
    ExpandedExpandLess,
    SquaredIconButton,
    StyledTable,
    WhiteBackgroundStack,
    StyledIconButton,
} from "features/infobox/components/DefaultInfobox/DefaultInfobox.styles"
import copyButton from "utils/icons/copy-icon.svg"
import PropertiesDisplay from "../PropertiesDisplay"

type Props = {
    boatDetails: BoatDetailsInfobox
    collapsed: boolean
    index: number
    subscriptionName: string
    hasMultipleFeatures: boolean
    onCopyClick: (index: number) => void
    onToggleFeature: (index: number) => void
}

const AisArchiveFeature: FC<Props> = ({
    boatDetails,
    collapsed,
    index,
    subscriptionName,
    hasMultipleFeatures,
    onCopyClick,
    onToggleFeature,
}) => {
    const { properties, hasPropertiesToDisplay } = useBuildProperties({
        featureProperties: boatDetails,
    })

    return (
        <div key={index}>
            <WhiteBackgroundStack alignItems="center" direction="row" flexWrap="wrap" position="sticky" top={0}>
                <Box
                    boxSizing="border-box"
                    display="flex"
                    flexBasis={0}
                    flexGrow={1}
                    maxWidth="100%"
                    overflow="hidden"
                    padding={theme => theme.spacing(1, 0, 1, 2)}
                >
                    {hasMultipleFeatures && (
                        <Tooltip title={collapsed ? "Expand" : "Collapse"}>
                            <StyledIconButton size="small" onClick={() => onToggleFeature(index)}>
                                {collapsed ? <ExpandedExpandLess /> : <CollapsedExpandLess />}
                            </StyledIconButton>
                        </Tooltip>
                    )}
                    <TruncatedTypography fontWeight={700} variant="subtitle1" showTooltip>
                        {subscriptionName} - Archive
                    </TruncatedTypography>
                </Box>
                <Box
                    flex="0 0 auto"
                    paddingRight={theme => (hasMultipleFeatures ? theme.spacing(2.5) : theme.spacing(3.8))}
                >
                    {hasPropertiesToDisplay && (
                        <Tooltip arrow placement="bottom" title="Copy the layer's name and properties">
                            <SquaredIconButton size="small" onClick={() => onCopyClick(index)}>
                                <img alt="" src={copyButton} />
                            </SquaredIconButton>
                        </Tooltip>
                    )}
                </Box>
            </WhiteBackgroundStack>
            {hasMultipleFeatures ? (
                <Collapse in={!collapsed} timeout={200} unmountOnExit>
                    <Box
                        borderLeft={theme => `1px solid ${theme.customColors.borderColor}`}
                        marginLeft={theme => theme.spacing(3.5)}
                        marginTop={theme => theme.spacing(1)}
                    >
                        <StyledTable>
                            <TableBody>
                                <PropertiesDisplay properties={properties} />
                            </TableBody>
                        </StyledTable>
                    </Box>
                </Collapse>
            ) : (
                <StyledTable>
                    <TableBody>
                        <PropertiesDisplay properties={properties} />
                    </TableBody>
                </StyledTable>
            )}
        </div>
    )
}

export default AisArchiveFeature
