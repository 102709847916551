import { FC, Fragment } from "react"
import LaunchIcon from "@mui/icons-material/Launch"
import { Box, CircularProgress, Link, Stack, TableRow, Tooltip } from "@mui/material"
import { CustomTypography } from "@windgis/shared"
import dayjs from "dayjs"
import { useSelector } from "react-redux"
import config from "config"
import {
    StyledTableCellKey,
    StyledTableCellValue,
} from "features/infobox/components/DefaultInfobox/DefaultInfobox.styles"
import { getSelectedAppIsPublic } from "selectors/appsSelectors"
import { getLayerStyles } from "selectors/layerSelector"
import { useAppSelector } from "store/hooks/useAppSelector"
import { useStyles } from "./styles"
import useDprLocationHistory from "./useDprLocationHistory"

type Props = {
    feature: mapboxgl.MapboxGeoJSONFeature
}

const DprProperties: FC<Props> = ({ feature }) => {
    const classes = useStyles()
    // @ts-ignore TODO: fix this
    const styles = useAppSelector(getLayerStyles(feature?.layer?.metadata?.resourceId))
    const isPublic: boolean = useAppSelector(getSelectedAppIsPublic)

    const { locationHistory, isError, isLoading, shouldDisplayInfo } = useDprLocationHistory({ feature })

    const getDisplayName = (historyEntry: any) => {
        if (
            (styles[0].type as any) === "pie-chart" ||
            (styles[0].type as any) === "box-chart" ||
            !!historyEntry.dprLink
        ) {
            return (
                <Link
                    color="primary"
                    href={isPublic ? config.dprShowcaseUrl : historyEntry.dprLink}
                    rel="noopener noreferrer"
                    target="_blank"
                    underline="none"
                >
                    <Stack direction="row" justifyContent="flex-end">
                        <Tooltip title="Open DPR in new tab">
                            <Box alignItems="center" display="flex" gap={0.5}>
                                {styles[0].properties.filter(x => x.title === "labels")?.[0]?.value[
                                    historyEntry.milestoneBitPosition
                                ] ?? historyEntry.milestoneDisplayName}

                                <LaunchIcon color="primary" fontSize="small" />
                            </Box>
                        </Tooltip>
                    </Stack>
                </Link>
            )
        } else {
            return historyEntry.milestoneDisplayName
        }
    }

    if (!shouldDisplayInfo) return null

    if (isLoading) {
        return (
            <tr>
                <td colSpan={2}>
                    <CircularProgress className={classes.status} size={20} />
                </td>
            </tr>
        )
    }

    if (isError) {
        return (
            <tr>
                <td colSpan={2}>
                    <CustomTypography className={classes.status} color="error" variant="body2">
                        Failed to fetch the DPR milestone information
                    </CustomTypography>
                </td>
            </tr>
        )
    }

    return (
        <>
            {!!locationHistory?.length && (
                <TableRow key="DPR Milestones">
                    <StyledTableCellKey>
                        <CustomTypography textWeight={"bold"} variant="body2">
                            DPR Milestones
                        </CustomTypography>
                    </StyledTableCellKey>
                    <StyledTableCellValue></StyledTableCellValue>
                </TableRow>
            )}

            {locationHistory?.map(historyEntry => (
                <Fragment key={`${historyEntry.locationName}-${historyEntry.milestoneId}-${historyEntry.dprDate}`}>
                    <TableRow key="Milestone Name">
                        <StyledTableCellKey isIndented={true}>
                            <CustomTypography textWeight={"bold"} variant="body2">
                                Milestone Name
                            </CustomTypography>
                        </StyledTableCellKey>
                        <StyledTableCellValue>
                            <CustomTypography variant="body2">{getDisplayName(historyEntry)}</CustomTypography>
                        </StyledTableCellValue>
                    </TableRow>
                    <TableRow key="Completion Date">
                        <StyledTableCellKey isIndented={true}>
                            <CustomTypography variant="body2">Completion Date</CustomTypography>
                        </StyledTableCellKey>
                        <StyledTableCellValue>
                            <CustomTypography variant="body2">
                                {dayjs(historyEntry.dprDate).format("L")}
                            </CustomTypography>
                        </StyledTableCellValue>
                    </TableRow>
                    <TableRow key="Vessel">
                        <StyledTableCellKey isIndented={true}>
                            <CustomTypography variant="body2">Vessel</CustomTypography>
                        </StyledTableCellKey>
                        <StyledTableCellValue>
                            <CustomTypography variant="body2">{historyEntry.vessel}</CustomTypography>
                        </StyledTableCellValue>
                    </TableRow>
                    <TableRow key="Scope">
                        <StyledTableCellKey isIndented={true}>
                            <CustomTypography variant="body2">Scope</CustomTypography>
                        </StyledTableCellKey>
                        <StyledTableCellValue>
                            <CustomTypography variant="body2">{historyEntry.scope}</CustomTypography>
                        </StyledTableCellValue>
                    </TableRow>
                </Fragment>
            ))}
        </>
    )
}

export default DprProperties
