import { FC } from "react"
import PublicIcon from "@mui/icons-material/Public"
import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material"
import { TruncatedTypography } from "@windgis/shared"
import dayjs from "dayjs"
import { App } from "model/app/App"
import { Link } from "react-router-dom"
import CustomChip from "components/CustomChip/CustomChip"
import config from "config"
import { getClientId, getProjectId } from "features/core/selectors"
import { useAppSelector } from "store/hooks/useAppSelector"
import { useStyles } from "./styles"

type Props = {
    app: App
    getPathname: () => string
    token: string
}

const AppCard: FC<Props> = ({ app, getPathname, token }) => {
    const classes = useStyles()

    const clientId = useAppSelector(getClientId)
    const projectId = useAppSelector(getProjectId)

    const getImageUrl = () => {
        return `${config.apiUrl}application/${app.id}/thumbnail?key=${token}&ClientId=${clientId}&ProjectId=${projectId}`
    }

    return (
        <Card className={classes.appCard}>
            <CardActionArea component={Link} data-testid={appCardId} to={getPathname()}>
                <CardMedia alt="" className={classes.image} component="img" image={getImageUrl()} />
                <CardContent className={classes.cardContent}>
                    <div>
                        <TruncatedTypography
                            color="black"
                            component="h6"
                            fontSize={18}
                            fontWeight="bold"
                            letterSpacing={0.19}
                            variant="h6"
                        >
                            {app.name}
                        </TruncatedTypography>
                        <Typography className={classes.modifiedUtcText} variant="body2">
                            Updated: {dayjs(app.modifiedUtc).fromNow()}
                        </Typography>
                    </div>
                    {app.public && (
                        <CustomChip
                            className={classes.blueChip}
                            expanded={true}
                            Icon={PublicIcon}
                            label={"Public"}
                            size="large"
                        />
                    )}
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default AppCard

const appCardId = "qa-app-card"
