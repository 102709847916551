import { PropsWithChildren, useCallback, useEffect, useRef } from "react"
import { IHubProtocol } from "@microsoft/signalr"
import { MessagePackHubProtocol } from "@microsoft/signalr-protocol-msgpack"
import useAuthentication from "app/hooks/useAuthentication"
import { useFetchIsAISPublicQuery } from "features/ais/api"
import { getClientId, getProjectId } from "features/core/selectors"
import { setBoats } from "reducers/ais"
import { getFeatureFlags } from "selectors/featureFlagsSelectors"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import { useAppSelector } from "store/hooks/useAppSelector"
import useSignalR from "utils/customHooks/useSignalR"

export type AISLayerProps = PropsWithChildren<{
    isPublicApp?: boolean
}>

const AisProvider = ({ children, isPublicApp = false }: AISLayerProps) => {
    // Fetch the AIS public flag
    const { data: { isPublic: isAisPublicQueryResponse } = { isPublic: false } } = useFetchIsAISPublicQuery(undefined, {
        skip: !isPublicApp,
    })

    // Get the feature flags for non-public app
    const featureFlags = useAppSelector(getFeatureFlags)

    const isAisEnabled = isPublicApp ? isAisPublicQueryResponse : featureFlags.AIS

    const protocol = useRef(new MessagePackHubProtocol())

    const clientId = useAppSelector(getClientId)

    const projectId = useAppSelector(getProjectId)

    const dispatch = useAppDispatch()

    const { getAccessToken } = useAuthentication()

    const tokenFactory = useCallback(() => getAccessToken(), [])

    const path = `ais/aishub?ClientId=${clientId}&ProjectId=${projectId}`

    const { addEvent, removeEvent, isConnectionStarted } = useSignalR(
        path,
        isPublicApp ? undefined : tokenFactory,
        protocol.current as IHubProtocol,
    )

    useEffect(() => {
        if (isAisEnabled && isConnectionStarted) {
            addEvent("aisResponse", data => {
                // Since union types don't get deserialized, we get an array instead of an object
                // index 0 is the union type, index 1 is the data

                dispatch(setBoats(data))
            })

            return () => {
                removeEvent("aisResponse")
            }
        }
    }, [isAisEnabled, isConnectionStarted])

    return children
}

export default AisProvider
