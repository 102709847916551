const colors = [
    "#ef5350",
    "#EC407A",
    "#AB47BC",
    "#7E57C2",
    "#5C6BC0",
    "#42A5F5",
    "#29B6F6",
    "#26C6DA",
    "#26A69A",
    "#66BB6A",
    "#9CCC65",
    "#D4E157",
    "#FFEE58",
    "#FFCA28",
    "#FFA726",
    "#FF7043",
    "#8D6E63",
    "#BDBDBD",
    "#78909C",
]

export const DPRColors = [
    "#024F79",
    "#277CA8",
    "#cae4f2",
    "#FFCE00",
    "#FFEB99",
    "#0393E2",
    "#82BCF1",
    "#E67B10",
    "#FBBB8A",
    "#2C7132",
    "#40A348",
    "#A1D29E",
    "#f19497",
    "#f9c4c5",
    "#7F6700",
    "#bf9b00",
    "#770279",
    "#BA76B7",
    "#FAD8F7",
    "#455158",
    "#96A4AD",
    "#acbb3c",
    "#E0E6C0",
]

export const paletteIdentifier = {
    DEFAULT: "DEFAULT",
    DPR: "DPR",
}

export function getColor(palette: string, index: number) {
    switch (palette) {
        case paletteIdentifier.DEFAULT:
            return colors[index % colors.length]
        case paletteIdentifier.DPR:
            return DPRColors[index % colors.length]
        default:
            return colors[index % colors.length]
    }
}

export function getColors(count: number) {
    let colorArray = []

    let colorIndex = 0
    for (let i = 0; i < count; i++) {
        colorArray.push(colors[colorIndex])

        if (colorIndex >= colors.length) {
            colorIndex = 0
        } else {
            colorIndex = i
        }
    }

    return colorArray
}

export function interpolateColors(color1: string, color2: string, steps: number) {
    let colorArray = []

    let stepValue = 1.0 / (steps + 1)

    for (let ratio = 0.0; ratio <= 1.0; ratio += stepValue) {
        colorArray.push(interpolateColor(color1, color2, ratio))
    }

    return colorArray
}

export function interpolateColorArray(colors: string[], steps: number) {
    let colorArray = []

    colorArray.push(colors[0])

    if (steps === 1) {
        return colorArray
    }

    // let stepValue = 1.0 / (steps + 1) ;
    let stepValue = (colors.length - 1) / (steps - 1)

    let colorValue = stepValue

    for (let i = 1; i < steps - 1; i++) {
        let color = Math.floor(colorValue)
        let ratio = colorValue % 1
        colorArray.push(interpolateColor(colors[color], colors[color + 1], ratio))
        colorValue += stepValue
    }

    colorArray.push(colors[colors.length - 1])

    // for (let ratio = 0.0; ratio <= 1.0; ratio += stepValue) {
    //     colorArray.push(interpolateColor(color1, color2, ratio));
    // }

    return colorArray
}

function interpolateColor(a: string, b: string, amount: number) {
    var ah = parseInt(a.replace(/#/g, ""), 16),
        ar = ah >> 16,
        ag = (ah >> 8) & 0xff,
        ab = ah & 0xff,
        bh = parseInt(b.replace(/#/g, ""), 16),
        br = bh >> 16,
        bg = (bh >> 8) & 0xff,
        bb = bh & 0xff,
        rr = ar + amount * (br - ar),
        rg = ag + amount * (bg - ag),
        rb = ab + amount * (bb - ab)

    return "#" + (((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1)
}
