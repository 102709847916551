import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Divider, styled } from "@mui/material"

export const StyledCollapsibleCardContainerDiv = styled("div")({
    "& ::-webkit-scrollbar": {
        height: 33,
        width: 20,
    },
    "& ::-webkit-scrollbar-track:vertical": {
        marginBottom: 64,
    },
    "& .MuiCollapse-entered": {
        overflow: "auto",
    },
    border: `1px solid #e3e4e5`,
    borderRadius: 6,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
})

export const StyledDivider = styled(Divider)({
    position: "sticky",
    top: 0,
    zIndex: 1,
})

export const StyledCollapsibleCardHeaderDiv = styled("div", { shouldForwardProp: prop => prop !== "padding" })<{
    padding?: number | [number, number] | [number, number, number, number]
}>(({ padding, theme }) => ({
    alignItems: "center",
    boxSizing: "border-box",
    cursor: "pointer",
    display: "flex",
    fontSize: 16,
    fontWeight: "bold",
    justifyContent: "space-between",
    minHeight: 54,
    padding: padding
        ? Array.isArray(padding)
            ? padding.length === 2
                ? theme.spacing(padding[0], padding[1])
                : theme.spacing(padding[0], padding[1], padding[2], padding[3])
            : theme.spacing(padding)
        : theme.spacing(1, 2),
}))

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)(({ theme }) => ({
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}))

export const StyledExpandLessIcon = styled(ExpandLessIcon)(({ theme }) => ({
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}))
