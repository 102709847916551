import { FC } from "react"
import { Box, Collapse, TableBody, TableRow, Tooltip } from "@mui/material"
import { TruncatedTypography } from "@windgis/shared"
import {
    CollapsedExpandLess,
    ExpandedExpandLess,
    StyledIconButton,
    StyledTable,
    StyledTableCellKey,
    StyledTableCellValue,
    WhiteBackgroundStack,
} from "features/infobox/components/DefaultInfobox/DefaultInfobox.styles"

type Props = {
    collapsed: boolean
    coordinatesPrecision?: number
    featureIndex: number
    lat: number
    long: number
    name: string
    rasterValuePrecision?: number
    value: number
    hasMultipleFeatures: boolean
    toggleFeature: (index: number) => void
}

const RasterFeature: FC<Props> = ({
    collapsed,
    coordinatesPrecision = 6,
    featureIndex,
    lat,
    long,
    name,
    rasterValuePrecision = 2,
    value,
    hasMultipleFeatures,
    toggleFeature,
}) => {
    return (
        <div>
            <WhiteBackgroundStack alignItems="center" direction="row" flexWrap="wrap" position="sticky" top={0}>
                <Box
                    boxSizing="border-box"
                    display="flex"
                    flexBasis={0}
                    flexGrow={1}
                    maxWidth="100%"
                    overflow="hidden"
                    padding={theme => theme.spacing(1, 0, 1, 2)}
                >
                    {hasMultipleFeatures && (
                        <Tooltip title={collapsed ? "Expand" : "Collapse"}>
                            <StyledIconButton size="small" onClick={_ => toggleFeature(featureIndex)}>
                                {collapsed ? <ExpandedExpandLess /> : <CollapsedExpandLess />}
                            </StyledIconButton>
                        </Tooltip>
                    )}
                    <TruncatedTypography fontWeight={700} variant="subtitle1">
                        {name}
                    </TruncatedTypography>
                </Box>
            </WhiteBackgroundStack>
            {hasMultipleFeatures ? (
                <Collapse in={!collapsed} timeout={200} unmountOnExit>
                    <Box
                        borderLeft={theme => `1px solid ${theme.customColors.borderColor}`}
                        marginLeft={theme => theme.spacing(3.5)}
                        marginTop={theme => theme.spacing(1)}
                    >
                        <StyledTable>
                            <TableBody>
                                <TableRow>
                                    <StyledTableCellKey>
                                        <TruncatedTypography fontWeight={700} variant="body2">
                                            {"Coord (lat, lng)"}
                                        </TruncatedTypography>
                                    </StyledTableCellKey>
                                    <StyledTableCellValue>
                                        <TruncatedTypography variant="body2">
                                            {lat.toFixed(coordinatesPrecision) +
                                                ", " +
                                                long.toFixed(coordinatesPrecision)}
                                        </TruncatedTypography>
                                    </StyledTableCellValue>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCellKey>
                                        <TruncatedTypography fontWeight={700} variant="body2">
                                            Value
                                        </TruncatedTypography>
                                    </StyledTableCellKey>
                                    <StyledTableCellValue>
                                        <TruncatedTypography variant="body2">
                                            {value?.toFixed(rasterValuePrecision) ?? "No data"}
                                        </TruncatedTypography>
                                    </StyledTableCellValue>
                                </TableRow>
                            </TableBody>
                        </StyledTable>
                    </Box>
                </Collapse>
            ) : (
                <StyledTable>
                    <TableBody>
                        <TableRow>
                            <StyledTableCellKey>
                                <TruncatedTypography fontWeight={700} variant="body2">
                                    {"Coord (lat, lng)"}
                                </TruncatedTypography>
                            </StyledTableCellKey>
                            <StyledTableCellValue>
                                <TruncatedTypography variant="body2">
                                    {lat.toFixed(coordinatesPrecision) + ", " + long.toFixed(coordinatesPrecision)}
                                </TruncatedTypography>
                            </StyledTableCellValue>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellKey>
                                <TruncatedTypography fontWeight={700} variant="body2">
                                    Value
                                </TruncatedTypography>
                            </StyledTableCellKey>
                            <StyledTableCellValue>
                                <TruncatedTypography variant="body2">
                                    {value?.toFixed(rasterValuePrecision) ?? "No data"}
                                </TruncatedTypography>
                            </StyledTableCellValue>
                        </TableRow>
                    </TableBody>
                </StyledTable>
            )}
        </div>
    )
}

export default RasterFeature
