import { Close, CloudDownload } from "@mui/icons-material"
import { IconButton, Tooltip, Typography } from "@mui/material"
import { TruncatedTypography } from "@windgis/shared"
import useAttributeTableHeaderStyles from "./styles"

type Props = {
    layerName: string
    numberOfSelectedFeatures: number
    isDownloadable: boolean
    onClose: () => void
    onDownloadClick: () => void
}

const AttributeTableHeader = ({
    layerName,
    numberOfSelectedFeatures,
    isDownloadable,
    onClose,
    onDownloadClick,
}: Props) => {
    const classes = useAttributeTableHeaderStyles()

    return (
        <div className={classes.header}>
            <TruncatedTypography
                color={theme => theme.customColors.white}
                paddingRight={86}
                textAlign="center"
                variant="h6"
            >
                Attribute table - {layerName}
            </TruncatedTypography>
            <div className={classes.downloadContainer}>
                {numberOfSelectedFeatures > 0 && (
                    <Typography className={classes.downloadSelectedValue} variant="subtitle2">
                        {numberOfSelectedFeatures}
                    </Typography>
                )}
                <Tooltip
                    title={
                        isDownloadable
                            ? numberOfSelectedFeatures > 0
                                ? "Download selected features"
                                : "Download all features"
                            : "Layer is not downloadable"
                    }
                >
                    <div>
                        <IconButton
                            aria-label="Download"
                            className={classes.downloadButton}
                            data-testid={downloadTestId}
                            size="small"
                            disabled={!isDownloadable}
                            onClick={onDownloadClick}
                        >
                            <CloudDownload />
                        </IconButton>
                    </div>
                </Tooltip>
            </div>
            <IconButton className={classes.closeButton} data-testid={closeTestId} size="large" onClick={onClose}>
                <Close />
            </IconButton>
        </div>
    )
}

export default AttributeTableHeader

const closeTestId = "qa-attribute-table-close-button"
const downloadTestId = "qa-attribute-table-download-button"
