import React, { useEffect, useState } from "react"
import {
    Typography,
    LinearProgress,
    Select,
    InputLabel,
    FormControl,
    MenuItem,
    Grid,
    FormControlLabel,
    Checkbox,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { unwrapResult } from "@reduxjs/toolkit"
import { getFilenameFromContentDisposition } from "@windgis/shared"
import { CustomModal } from "@windgis/shared"
import Download from "downloadjs"
import { useDispatch } from "react-redux"
import { downloadDataset } from "actions/layerSelector"
import useComponentCancelToken from "app/hooks/useComponentCancelToken"
import { handleError } from "utils/networkErrorUtils"

const dataTypes = [
    { name: "GeoJSON", prettyName: "GeoJson" },
    { name: "ESRI Shapefile", prettyName: "Shapefile" },
    { name: "KML", prettyName: "Kml" },
    { name: "GPKG", prettyName: "GeoPackage" },
    { name: "CSV", prettyName: "CSV" },
    { name: "XLSX", prettyName: "MS Excel" },
]

const useStyles = makeStyles(theme => ({
    headerText: {
        alignItems: "center",
        color: "#fff",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    layerName: {
        color: "#e0e0e0",
        fontSize: "1.25rem",
        padding: 5,
    },
    layerNameDownloadModal: {
        display: "flex",
        fontWeight: "bold",
        width: "100%",
    },
    modalHeader: {
        backgroundColor: "#024F79",
        color: "#fff",
        padding: theme.spacing(2),
        textAlign: "center",
    },
    modalText: {
        padding: 20,
        textAlign: "center",
    },
    root: {
        height: 220,
        left: "50%",
        outline: "none",
        position: "absolute",
        top: "50%",
        transform: "translate(-50%, -50%)",
        width: 450,
    },
    downloadDialogSelect: {
        width: "40%",
    },
    downloadDialogSelectContainer: {
        padding: 20,
    },
}))

const DownloadLayer = ({ featureIds, layer, open, handleClose }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [downloading, setDownloading] = useState(false)
    const [downloadProgress, setDownloadProgress] = useState(0)
    const [dataType, setDataType] = useState(dataTypes[0].name)
    const [includeMetadata, setIncludeMetadata] = useState(false)

    const [errorMessage, setErrorMessage] = useState(null)
    const [cancelToken, regenerateCancelToken] = useComponentCancelToken()

    useEffect(() => {
        return () => {
            setErrorMessage(null)
        }
    }, [dataType])

    const onDownload = () => {
        setDownloading(true)

        let config = {
            cancelToken: cancelToken.token,
            onDownloadProgress: progressEvent => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setDownloadProgress(percentCompleted)
            },
        }

        dispatch(
            downloadDataset({
                config,
                datasetId: layer.resourceId,
                featureIds,
                format: dataType,
                includeMetadata,
            }),
        )
            .then(unwrapResult)
            .then(res => {
                const filename = getFilenameFromContentDisposition(res.headers["content-disposition"] ?? "data")
                Download(res.data, filename)
                handleClose()
            })
            .catch(err => {
                handleError(err)
                setErrorMessage(err.message)
            })
            .finally(() => {
                setDownloading(false)
                setDownloadProgress(0)
            })
    }

    const handleCancel = () => {
        setDownloadProgress(0)
        cancelToken.cancel("Download cancelled")
        regenerateCancelToken()
    }

    const handleCloseFromDownloadLayer = () => {
        handleClose()
        setErrorMessage(null)
    }

    const getDataTestId = itemName => {
        const name = itemName.toLocaleLowerCase().replaceAll(" ", "-")
        return downloadItemTestId + name
    }

    return (
        <CustomModal
            dialogTitle={"Download Layer"}
            dialogType={"start"}
            disabled={downloading}
            isOpen={open}
            handleCancel={handleCancel}
            handleClose={handleCloseFromDownloadLayer}
            onConfirm={onDownload}
        >
            <Grid container>
                {downloading && (
                    <Grid item xs={12}>
                        {downloadProgress === 0 ? (
                            <LinearProgress variant="indeterminate" />
                        ) : (
                            <LinearProgress value={downloadProgress} variant="determinate" />
                        )}
                    </Grid>
                )}
                {!downloading && (
                    <Grid item xs={12}>
                        <Typography className={classes.layerNameDownloadModal} component="div" gutterBottom>
                            {layer.name}
                        </Typography>
                        <FormControl className="section" fullWidth variant="filled">
                            <InputLabel id="select-type-of-layer">Type of layer</InputLabel>
                            <Select
                                label="Type of layer"
                                labelId="select-type-of-layer"
                                value={dataType}
                                onChange={e => setDataType(e.target.value)}
                            >
                                {dataTypes.map(dataType => (
                                    <MenuItem
                                        key={dataType.name}
                                        data-testid={getDataTestId(dataType.name)}
                                        value={dataType.name}
                                    >
                                        {dataType.prettyName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={includeMetadata}
                                    data-testid={includeMetadataTestId}
                                    onChange={e => setIncludeMetadata(e.target.checked)}
                                />
                            }
                            label="Include metadata"
                            sx={{ gap: 0.5, marginLeft: 0 }}
                        />
                    </Grid>
                )}
                {downloading && (
                    <Grid item xs={12}>
                        {downloadProgress === 0 ? (
                            <Typography className={classes.modalText} color="primary" variant="h6">
                                Generating File
                            </Typography>
                        ) : (
                            <Typography className={classes.modalText} color="primary" variant="h6">
                                Downloading... {downloadProgress}%
                            </Typography>
                        )}
                    </Grid>
                )}
                {errorMessage && (
                    <Grid item xs={12}>
                        <Typography className={classes.errorModalText} color="error">
                            {errorMessage}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </CustomModal>
    )
}

export default DownloadLayer

const downloadItemTestId = "qa-download-"
const includeMetadataTestId = "qa-download-include-metadata"
