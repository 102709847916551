import { css, Stack, styled, Theme } from "@mui/material"

export const StyledVesselsContainerStack = styled(Stack)(
    ({ theme }) => css`
        // 124 => app bar height(48px + 1px divider) + feature title height(46px + 1px divider) + padding
        height: calc(100vh - 124px);

        [${(theme as Theme).breakpoints.down("md")}] {
            max-height: 100%;
        }
    `,
)

export const StyledWarningStack = styled(Stack)`
    padding: 12px 16px;
    align-items: center;
    color: #0069cc;
    gap: 8px;
    background-color: #f0f6ff;
    flex-direction: row;
    border-radius: 8px;
    margin-bottom: 16px;
    font-weight: 600;
`
