import React, { forwardRef, PropsWithChildren } from "react"
import { Collapse, Box, BoxProps, IconButton } from "@mui/material"
import {
    StyledCollapsibleCardContainerDiv,
    StyledCollapsibleCardHeaderDiv,
    StyledDivider,
    StyledExpandLessIcon,
    StyledExpandMoreIcon,
} from "./CollapsibleCard.styles"

export type CollapsibleCardProps = PropsWithChildren<
    {
        headerPadding?: number | [number, number] | [number, number, number, number]
        unmountOnExit?: boolean
        isToggled: boolean
        isToggleDisabled?: boolean
        onToggleClick: () => void
    } & (
        | {
              Component?: never
              id?: never
              title?: string
          }
        | {
              Component?: React.ReactElement
              id?: string
              title?: never
          }
    ) &
        Omit<BoxProps, "id" | "title">
>

const CollapsibleCard = forwardRef<HTMLDivElement, CollapsibleCardProps>(
    (
        {
            children,
            Component,
            headerPadding,
            id,
            padding = 2,
            title,
            unmountOnExit,
            isToggled,
            isToggleDisabled,
            onToggleClick,
            ...restBoxProps
        },
        ref,
    ) => {
        const dataTestId = collapsibleCardTestId + (title ?? id ?? "").toLocaleLowerCase().replaceAll(" ", "-")

        return (
            <StyledCollapsibleCardContainerDiv ref={ref}>
                <StyledCollapsibleCardHeaderDiv
                    padding={headerPadding}
                    onClick={isToggleDisabled ? undefined : onToggleClick}
                >
                    {!!Component ? Component : <Box width="100%">{title ?? ""}</Box>}

                    <IconButton data-testid={dataTestId} disabled={isToggleDisabled}>
                        {isToggled ? <StyledExpandLessIcon /> : <StyledExpandMoreIcon />}
                    </IconButton>
                </StyledCollapsibleCardHeaderDiv>

                <Collapse in={isToggleDisabled ? false : isToggled} unmountOnExit={unmountOnExit}>
                    <StyledDivider />

                    <Box padding={padding} {...restBoxProps}>
                        {children}
                    </Box>
                </Collapse>
            </StyledCollapsibleCardContainerDiv>
        )
    },
)

export default CollapsibleCard

const collapsibleCardTestId = "qa-collapsible-card-"
