import React, { useState } from "react"
import { Grid, MenuItem, Select, FormControl, InputLabel } from "@mui/material"
import { CustomModal } from "@windgis/shared"
import Download from "downloadjs"
import JSZip from "jszip"
import { useSelector, useDispatch } from "react-redux"
import sanitize from "sanitize-filename"
import tokml from "tokml"
import { convertAndDownload } from "actions/download"
import fileFormats from "constants/files/fileFormats"
import { fileTypes } from "constants/files/fileTypes"
import { getDigitizeFeatures, getDigitizeLayersForDownloadSelector } from "selectors/digitizeSelectors"
import { useMainDraw } from "utils/customHooks/map/useMainDraw"
import { fileTypeToDownloadEnum } from "utils/files/fileTypeToDownloadEnum"
import { fileTypeToDownloadExtension } from "utils/files/fileTypeToDownloadExtension"
import { handleError } from "utils/networkErrorUtils"

const fileTypeOptions = [fileTypes.GEO_JSON, fileTypes.GEO_PACKAGE, fileTypes.SHAPEFILE]

const DownloadDialog = ({ layer = null, open, downloadAllLayers, onClose }) => {
    const draw = useMainDraw()

    const [selectedDownloadDialogValue, setSelectedDownloadDialogValue] = useState(fileTypeOptions[0])
    const layers = useSelector(getDigitizeLayersForDownloadSelector(downloadAllLayers))
    const digitizeFeatures = useSelector(getDigitizeFeatures)

    const dispatch = useDispatch()

    const handleClose = () => {
        onClose()
    }

    const handleChange = e => {
        setSelectedDownloadDialogValue(e.target.value)
    }

    const downloadWithBackend = (featureCollection, fileName) => {
        const fileBlob = new Blob([JSON.stringify(featureCollection)], {
            type: "application/json",
        })
        const fileExtension = fileTypeToDownloadExtension(selectedDownloadDialogValue)

        dispatch(
            convertAndDownload(
                fileBlob,
                fileFormats.GEO_JSON,
                fileTypeToDownloadEnum(selectedDownloadDialogValue),
                fileName,
            ),
        )
            .then(blob => {
                Download(blob, `${fileName}.${fileExtension}`)
            })
            .catch(err => {
                handleError(err)
            })
    }

    const downloadLayerFeatures = () => {
        let featuresToBeDownloadedObject
        if (!!layer) {
            let drawnFeatures = draw.getAll()
            let customDownloadFeatures = drawnFeatures.features.filter(drawnFeature =>
                layer.features.includes(drawnFeature.id),
            )
            featuresToBeDownloadedObject = { ...drawnFeatures }
            featuresToBeDownloadedObject.features = customDownloadFeatures
        } else {
            featuresToBeDownloadedObject = draw.getAll()
        }

        featuresToBeDownloadedObject.features = featuresToBeDownloadedObject.features.map(feature => ({
            ...feature,
            properties: { fid: feature.id },
        }))

        const featureNameAndIdMap = digitizeFeatures.reduce((acc, feature) => {
            acc[feature.id] = feature.properties.name
            return acc
        }, {})

        const featureIdMap = featuresToBeDownloadedObject.features.reduce((acc, feature) => {
            acc[feature.id] = {
                ...feature,
                properties: { ...feature.properties, name: featureNameAndIdMap[feature.id] },
            }
            return acc
        }, {})

        const targetLayers = layer ? [layer] : layers

        var zip = new JSZip()
        switch (selectedDownloadDialogValue) {
            case fileTypes.GEO_JSON:
            case fileTypes.GEO_PACKAGE:
                targetLayers.forEach(layer => {
                    const sanitizedFileName = sanitize(layer.name)
                    const featureCollection = {
                        features: [],
                        name: sanitizedFileName,
                        type: "FeatureCollection",
                    }
                    layer.features.forEach(featureId => {
                        featureCollection.features.push({
                            ...featureIdMap[featureId],
                            name: featureNameAndIdMap[featureId],
                        })
                    })
                    zip.file(
                        generateFileNameAndExtension(sanitizedFileName, selectedDownloadDialogValue, zip),
                        generateFileContentFromFeatures(featureCollection, selectedDownloadDialogValue),
                    )
                })
                zip.generateAsync({ type: "blob" }).then(blob => {
                    Download(blob, "DigitizeLayers.zip")
                })
                break
            case fileTypes.CSV:
            case fileTypes.SHAPEFILE:
                targetLayers.forEach(layer => {
                    const sanitizedFileName = sanitize(layer.name)
                    const featureCollection = {
                        features: [],
                        name: sanitizedFileName,
                        type: "FeatureCollection",
                    }
                    layer.features.forEach(featureId => {
                        featureCollection.features.push({
                            ...featureIdMap[featureId],
                            name: featureNameAndIdMap[featureId],
                        })
                    })

                    downloadWithBackend(featureCollection, sanitizedFileName)
                })
                break
            default:
                break
        }
    }

    const generateFileNameAndExtension = (layerName, fileType, zip) => {
        const fileExtension = fileTypeToDownloadExtension(fileType)
        let fileName = layerName
        let fileNameRegex = new RegExp(fileName + "." + fileExtension)
        let fileIndex = 0

        while (zip.file(fileNameRegex).length) {
            fileIndex += 1
            fileNameRegex = new RegExp(fileName + `\\(${fileIndex}\\).` + fileExtension)
        }

        fileName = fileIndex === 0 ? fileName : fileName + `(${fileIndex})`
        return fileName + "." + fileExtension
    }

    const isCsvEnabled = layer ? layer?.type === "point" : !layers.some(dLayer => dLayer.type !== "point")

    //returns a string
    const generateFileContentFromFeatures = (featureCollection, fileType) => {
        switch (fileType) {
            case "GeoJSON":
                return JSON.stringify(featureCollection)
            case "KML":
                return tokml(featureCollection)
            default:
                return null
        }
    }

    return (
        <CustomModal
            dialogTitle={
                layer
                    ? "Download features for"
                    : downloadAllLayers
                      ? "Download all layers."
                      : "Download selected layers."
            }
            dialogType={"start"}
            isOpen={open}
            handleClose={handleClose}
            onConfirm={downloadLayerFeatures}
        >
            <Grid alignItems="center" container>
                <FormControl fullWidth variant="filled">
                    <InputLabel id="digitize-select-file-type-label">Type of layer</InputLabel>
                    <Select
                        id="digitize-select-file-type"
                        labelId="digitize-select-file-type-label"
                        value={selectedDownloadDialogValue}
                        onChange={handleChange}
                    >
                        {fileTypeOptions.map(fileType => (
                            <MenuItem key={fileType} value={fileType}>
                                {fileType}
                            </MenuItem>
                        ))}
                        {isCsvEnabled && <MenuItem value={fileTypes.CSV}>{fileTypes.CSV}</MenuItem>}
                    </Select>
                </FormControl>
            </Grid>
        </CustomModal>
    )
}

export default DownloadDialog
