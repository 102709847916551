import { FC } from "react"
import ColorLensIcon from "@mui/icons-material/ColorLens"
import { Box, Collapse, TableBody, Tooltip } from "@mui/material"
import { TruncatedTypography } from "@windgis/shared"
import { setSidebarOpen } from "actions/sidebarAndDrawer"
import { useBuildProperties } from "components/map/infoBoxes/hooks/useBuildProperties"
import { useFetchDprDatasetColumnsQuery } from "features/dprIntegration/api"
import {
    CollapsedExpandLess,
    ExpandedExpandLess,
    SquaredIconButton,
    StyledIconButton,
    StyledTable,
    WhiteBackgroundStack,
} from "features/infobox/components/DefaultInfobox/DefaultInfobox.styles"
import { setStylerLayerId } from "reducers/layerSelector"
import { getSelectedAppIsPublic } from "selectors/appsSelectors"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import { useAppSelector } from "store/hooks/useAppSelector"
import copyButton from "utils/icons/copy-icon.svg"
import DprProperties from "../DprProperties/DprProperties"
import useInfoboxToUse from "../DprProperties/useInfoboxToUse"
import PropertiesDisplay from "../PropertiesDisplay"

type Props = {
    collapsed: boolean
    feature: mapboxgl.MapboxGeoJSONFeature
    featureIndex: number
    sourceToNameMapping: Record<string, string>
    hasMultipleFeatures: boolean
    toggleFeature: (index: number) => void
    onCopyClick: (index: number) => void
}

const VectorFeature: FC<Props> = ({
    collapsed,
    feature,
    featureIndex,
    sourceToNameMapping,
    hasMultipleFeatures,
    toggleFeature,
    onCopyClick,
}) => {
    const dispatch = useAppDispatch()

    const infoboxToUse = useInfoboxToUse(feature.sourceLayer)
    const isPublic: boolean = useAppSelector(getSelectedAppIsPublic)

    const { data: dprDatasetColumns = null } = useFetchDprDatasetColumnsQuery(
        { datasetId: feature.sourceLayer ?? "", isPublic },
        { skip: infoboxToUse !== "dpr" || !feature.sourceLayer },
    )

    const { properties, hasPropertiesToDisplay } = useBuildProperties({
        featureProperties: feature.properties,
        latestCompletedColumn: dprDatasetColumns?.latestCompletedColumn.prettyName,
        progressColumn: dprDatasetColumns?.progressColumn.prettyName,
    })

    const onEditLayerStyleClick = () => {
        dispatch(setSidebarOpen(true))
        // @ts-ignore TODO: fix this
        dispatch(setStylerLayerId(feature?.layer?.metadata?.resourceId ?? ""))
    }

    return (
        <div key={featureIndex}>
            <WhiteBackgroundStack alignItems="center" direction="row" flexWrap="wrap" position="sticky" top={0}>
                <Box
                    boxSizing="border-box"
                    display="flex"
                    flexBasis={0}
                    flexGrow={1}
                    maxWidth="100%"
                    overflow="hidden"
                    padding={theme => theme.spacing(1, 0, 1, 2)}
                >
                    {hasMultipleFeatures && (
                        <Tooltip title={collapsed ? "Expand" : "Collapse"}>
                            <StyledIconButton size="small" onClick={_ => toggleFeature(featureIndex)}>
                                {collapsed ? <ExpandedExpandLess /> : <CollapsedExpandLess />}
                            </StyledIconButton>
                        </Tooltip>
                    )}
                    <TruncatedTypography fontWeight={700} variant="subtitle1">
                        {sourceToNameMapping[feature.sourceLayer || feature.source]}
                    </TruncatedTypography>
                </Box>
                <Box
                    flex="0 0 auto"
                    paddingRight={theme => (hasMultipleFeatures ? theme.spacing(2.5) : theme.spacing(3.8))}
                >
                    {hasPropertiesToDisplay && (
                        <Tooltip arrow placement="bottom" title="Copy the layer's name and properties">
                            <SquaredIconButton size="small" onClick={() => onCopyClick(featureIndex)}>
                                <img alt="" src={copyButton} />
                            </SquaredIconButton>
                        </Tooltip>
                    )}

                    {!feature.properties?.MMSI && !isPublic && (
                        <Tooltip placement="bottom" title="Edit layer style">
                            <SquaredIconButton size="small" onClick={() => onEditLayerStyleClick()}>
                                <ColorLensIcon color="primary" />
                            </SquaredIconButton>
                        </Tooltip>
                    )}
                </Box>
            </WhiteBackgroundStack>
            {hasMultipleFeatures ? (
                <Collapse in={!collapsed} timeout={200} unmountOnExit>
                    <Box
                        borderLeft={theme => `1px solid ${theme.customColors.borderColor}`}
                        marginLeft={theme => theme.spacing(3.5)}
                        marginTop={theme => theme.spacing(1)}
                    >
                        <StyledTable>
                            <TableBody>
                                <PropertiesDisplay properties={properties} />
                                <DprProperties feature={feature} />
                            </TableBody>
                        </StyledTable>
                    </Box>
                </Collapse>
            ) : (
                <StyledTable>
                    <TableBody>
                        <PropertiesDisplay properties={properties} />
                        <DprProperties feature={feature} />
                    </TableBody>
                </StyledTable>
            )}
        </div>
    )
}

export default VectorFeature
