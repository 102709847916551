import type { FilterType } from "../../../../contexts/FilterContext"
import React, { useEffect, useState } from "react"
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack } from "@mui/material"
import dayjs, { Dayjs } from "dayjs"
import { selectableOperatorsByType, useFilterContext } from "../../../../contexts/FilterContext"
import { Column } from "../../models/Column"
import { Types } from "../../models/FilterType"
import { Operators, operatorNameMapping } from "../../models/OperatorType"
import { dummyFilter } from "../FiltersList/FiltersList"
import FilterValueEditor from "../FilterValueEditor/FilterValueEditor"

type FilterContainerProps = {
    columns: Column[]
}

const SPACE_BETWEEN_FIELDS = 2
const SPACE_BETWEEN_FIELDS_AND_BUTTONS = 3

const FilterContainer = ({ columns }: FilterContainerProps) => {
    const context = useFilterContext()

    const filteredColumns = columns.filter(col => !!selectableOperatorsByType[col.type as Types])

    if (context.selectedFilter === null) return null

    const getInitialFilter = (selectedColumn: Column) => {
        switch (selectedColumn.type) {
            case Types.Date:
                return {
                    applied: false,
                    fieldName: selectedColumn.name,
                    operatorName: "",
                    secondValue: dayjs(),
                    type: selectedColumn.type,
                    value: dayjs(),
                } as FilterType

            default:
                return {
                    applied: false,
                    fieldName: selectedColumn.name,
                    operatorName: "",
                    type: selectedColumn.type,
                    value: null,
                } as FilterType
        }
    }

    const handleColumnChange = (selectedColumn: Column) => {
        const initialFilter: FilterType = getInitialFilter(selectedColumn)
        context.updateFilter(initialFilter ?? dummyFilter)
    }

    const handleOperatorChange = (operator: Operators) => {
        const updatedFilter = {
            ...context.selectedFilter,
            operatorName: operator,
        }
        context.updateFilter(updatedFilter as FilterType)
    }

    const handleValueChange = (value: string | Dayjs | number) => {
        const updatedFilter = {
            ...context.selectedFilter,
            value: value,
        } as FilterType
        context.updateFilter(updatedFilter)
    }

    const handleSecondValueChange = (value: string | number | Dayjs) => {
        const updatedFilter = {
            ...context.selectedFilter!,
            secondValue: value,
        } as FilterType
        context.updateFilter(updatedFilter)
    }

    const handleReset = () => {
        context.emptySelectedFilter()
    }

    const handleSave = () => {
        context.applyFilter()
    }

    const handleFieldChange = (e: any) => {
        const selectedColumn = filteredColumns.find(col => col.name === e.target.value)
        selectedColumn && handleColumnChange(selectedColumn)
    }

    const getColumnTestId = (column: Column) => {
        const formattedColumnName = column.name.toLocaleLowerCase().replace(/ /g, "-")
        return `${columnTestId}${formattedColumnName}`
    }

    const getOperatorTestId = (operator: Operators) => {
        const formattedOperatorName = operator.toLocaleLowerCase().replace(/ /g, "-")
        return `${operatorTestId}${formattedOperatorName}`
    }

    return (
        <Stack gap={SPACE_BETWEEN_FIELDS_AND_BUTTONS}>
            <Grid container spacing={SPACE_BETWEEN_FIELDS}>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="filled">
                        <InputLabel>Column</InputLabel>
                        <Select
                            inputProps={{ "data-testid": selectColumnTestId }}
                            value={context.selectedFilter.fieldName}
                            onChange={handleFieldChange}
                        >
                            {filteredColumns.map(column => (
                                <MenuItem key={column.id} data-testid={getColumnTestId(column)} value={column.name}>
                                    {column.prettyName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth variant="filled">
                        <InputLabel>Operator</InputLabel>
                        <Select
                            inputProps={{ "data-testid": selectOperatorTestId }}
                            value={context.selectedFilter.operatorName}
                            disabled={!context.selectedFilter.fieldName}
                            onChange={e => handleOperatorChange(e.target.value as Operators)}
                        >
                            {context.selectedFilter &&
                                selectableOperatorsByType[context.selectedFilter.type].map(operator => (
                                    <MenuItem
                                        key={operator.toString()}
                                        data-testid={getOperatorTestId(operator)}
                                        value={operator}
                                        disabled={operator === Operators.Undefined}
                                    >
                                        {operatorNameMapping[operator]}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={8}>
                    <FilterValueEditor
                        filter={context.selectedFilter}
                        label={context.selectedFilter.operatorName === Operators.Between ? "Smaller value" : "Value"}
                        value={context.selectedFilter.value}
                        handleValueChange={handleValueChange}
                    />
                </Grid>
                {context.selectedFilter.operatorName === Operators.Between && (
                    <>
                        <Grid item xs={4} />
                        <Grid item xs={8}>
                            <FilterValueEditor
                                filter={context.selectedFilter}
                                label="Greater value"
                                value={context.selectedFilter.secondValue}
                                handleValueChange={handleSecondValueChange}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            <Stack direction="row" gap={SPACE_BETWEEN_FIELDS}>
                <Button
                    data-testid={applyTestId}
                    variant="contained"
                    disabled={!context.areAllFiltersSet()}
                    onClick={handleSave}
                >
                    Apply
                </Button>
                <Button data-testid={resetTestId} variant="outlined" onClick={handleReset}>
                    Reset
                </Button>
            </Stack>
        </Stack>
    )
}

export default FilterContainer

const selectColumnTestId = "qa-filter-container-select-column"
const columnTestId = "qa-filter-container-column-"
const selectOperatorTestId = "qa-filter-container-select-operator"
const operatorTestId = "qa-filter-container-operator-"
const applyTestId = "qa-filter-container-apply-button"
const resetTestId = "qa-filter-container-reset-button"
