import { useFetchDprDatasetColumnsQuery, useFetchLocationStatusHistoryQuery } from "features/dprIntegration/api"
import { getSelectedAppIsPublic } from "selectors/appsSelectors"
import { useAppSelector } from "store/hooks/useAppSelector"
import useInfoboxToUse from "./useInfoboxToUse"

type Props = {
    feature: mapboxgl.MapboxGeoJSONFeature
}
const useDprLocationHistory = ({ feature }: Props) => {
    const infoboxToUse = useInfoboxToUse(feature.sourceLayer)
    const isPublic: boolean = useAppSelector(getSelectedAppIsPublic)

    const {
        data: dprDatasetColumns = null,
        isError: columnsError,
        isLoading: columnsLoading,
    } = useFetchDprDatasetColumnsQuery(
        { datasetId: feature?.sourceLayer ?? "", isPublic },
        { skip: infoboxToUse !== "dpr" || !feature?.sourceLayer },
    )

    const {
        data: locationHistory = null,
        isError: historyError,
        isLoading: historyLoading,
    } = useFetchLocationStatusHistoryQuery(
        {
            datasetId: feature?.sourceLayer ?? "",
            locationName: feature.properties?.[dprDatasetColumns?.locationColumn.name ?? ""] ?? "",
            isPublic,
        },
        {
            skip:
                infoboxToUse !== "dpr" ||
                columnsLoading ||
                columnsError ||
                !dprDatasetColumns?.locationColumn ||
                !feature?.sourceLayer,
        },
    )

    return {
        locationHistory,
        isError: columnsError || historyError,
        isLoading: columnsLoading || historyLoading,
        shouldDisplayInfo: infoboxToUse === "dpr",
    }
}

export default useDprLocationHistory
