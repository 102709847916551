import { FC } from "react"
import { InteractionType } from "@azure/msal-browser"
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import ReduxToastr from "react-redux-toastr"
import { Redirect, Route, Switch } from "react-router-dom"
import "@emblautec/mapbox-gl/dist/mapbox-gl.css"
import "@emblautec/recursive-array-extensions"
import { MsalErrorComponent } from "app/components/MsalComponents/MsalErrorComponent"
import { MsalLoaderComponent } from "app/components/MsalComponents/MsalLoaderComponent"
import config from "config"
import ErrorView from "views/error/ErrorView"
import LandingView from "views/landing/LandingView"
import MainPublicView from "views/main/MainPublicView"
import MainView from "views/main/MainView"
import SignOutView from "views/SignOutView/SignOutView"
import "./App.css"
import Bootstrap from "./Bootstrap"
import { AisProvider } from "./providers"
import PublicBootstrap from "./PublicBootstrap"
import "./scss/main.scss"

const App: FC = () => (
    <div className="App">
        <Switch>
            <Route exact path="/:clientId/:projectId/error">
                <ErrorView />
            </Route>
            <Route path="/:clientId/:projectId/public/:appId/map">
                <PublicBootstrap>
                    <AisProvider isPublicApp>
                        <MainPublicView />
                    </AisProvider>
                </PublicBootstrap>
            </Route>
            <Route path="/:clientId/:projectId">
                <MsalAuthenticationTemplate
                    authenticationRequest={config.loginRequest}
                    errorComponent={MsalErrorComponent}
                    interactionType={InteractionType.Redirect}
                    loadingComponent={MsalLoaderComponent}
                >
                    <Bootstrap>
                        <AisProvider>
                            <MainView />
                        </AisProvider>
                    </Bootstrap>
                </MsalAuthenticationTemplate>
            </Route>
            <Route exact path="/sign-out">
                <SignOutView />
            </Route>
            <Route exact path="/">
                <LandingView />
            </Route>
            <Redirect to="/" />
        </Switch>
        <ReduxToastr
            closeOnToastrClick
            newestOnTop={true}
            position="top-right"
            progressBar
            timeOut={4000}
            transitionIn="fadeIn"
            transitionOut="fadeOut"
        />
    </div>
)

export default App
