import { KeyboardEvent, useEffect, useState } from "react"
import { Close } from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"
import { Divider, IconButton, Input, InputAdornment } from "@mui/material"
import { CustomTypography } from "@windgis/shared"
import clsx from "clsx"
import proj4 from "proj4"
import { Marker, useMap } from "@emblautec/react-map-gl"
import { DEFAULT_PROJECTION } from "features/mapTools/constants"
import { getSelectedProjection } from "features/mapTools/selectors"
import { toggleWidget } from "features/mapTools/slice"
import { getMapClickPos } from "selectors/mapSelectors"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import { useAppSelector } from "store/hooks/useAppSelector"
import widgetTypes from "utils/tools/widgets"
import { useCoordinatesSearchStyles } from "./styles"

const coordinatesRegEx = /^-?\d+(?:\.\d+)?,\s*-?\d+(?:\.\d+)?$/

const CoordinatesSearch = () => {
    const classes = useCoordinatesSearchStyles()
    const [searchText, setSearchText] = useState("")
    const [error, setError] = useState<string | null>(null)
    const [coordinates, setCoordinates] = useState<{ lat: number; lon: number } | null>(null)
    const mapClickPos = useAppSelector(getMapClickPos)
    const selectedProjection = useAppSelector(getSelectedProjection)
    const dispatch = useAppDispatch()
    const map = useMap()

    useEffect(() => {
        if (!mapClickPos) return

        const [lon, lat] = proj4(selectedProjection.value, [mapClickPos.lng, mapClickPos.lat])

        setCoordinates({ lat: mapClickPos.lat, lon: mapClickPos.lng })
        setSearchText(`${lon}, ${lat}`)
    }, [mapClickPos])

    const onSearch = (e: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (e.key !== "Enter") return

        if (!coordinatesRegEx.test(searchText)) {
            setError('Please enter X and Y in the format "X,Y"')
            return
        }

        let lon = Number(searchText.split(",")[0])
        let lat = Number(searchText.split(",")[1])
        ;[lon, lat] = proj4(selectedProjection.value, DEFAULT_PROJECTION.value, [lon, lat])

        if (lat < -90 || lat > 90) {
            setError("Invalid latitude")
            return
        }

        setCoordinates({ lat, lon })
        setError(null)

        map.current?.flyTo({
            center: [lon, lat],
            curve: 1,
            speed: 0.5,
            zoom: 7,
        })
    }

    return (
        <div className={classes.root}>
            <Divider />
            <div className={classes.header}>
                <CustomTypography className={classes.headerText} variant="h6">
                    Search Location
                </CustomTypography>
                <IconButton onClick={() => dispatch(toggleWidget(widgetTypes.SEARCH))}>
                    <Close />
                </IconButton>
            </div>
            <Divider />
            <div className={classes.searchContainer}>
                <Input
                    endAdornment={
                        <InputAdornment position="end">
                            {searchText !== "" && (
                                <IconButton
                                    className={clsx(classes.closeBtn, classes.squareBtn)}
                                    size="large"
                                    onClick={() => setSearchText("")}
                                >
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    }
                    fullWidth
                    placeholder="X, Y (ex: 12.34,56.78)"
                    style={{ fontSize: 14 }}
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                    onKeyDown={onSearch}
                />
                {error && <CustomTypography className={classes.searchError}>{error}</CustomTypography>}
            </div>
            {coordinates && <Marker latitude={coordinates.lat} longitude={coordinates.lon} />}
        </div>
    )
}

export default CoordinatesSearch
