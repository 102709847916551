import { ExpandLess } from "@mui/icons-material";
import { Box, Button, css, IconButton, Stack, styled, Table, TableCell } from "@mui/material";

type StyledTableCellKeyProps = {
    isIndented?: boolean
};

export const WhiteBackgroundStack = styled(Stack)(({ theme }) => css`
    background-color: ${theme.customColors.white};
`)

export const StyledTable = styled(Table)(({ theme }) => ({
    '& tr:last-of-type td': {
        borderBottom: 0,
    },
    backgroundColor: 'white',
    borderCollapse: 'collapse',
    marginInline: theme.spacing(2),
    tableLayout: 'fixed',
    width: `calc(100% - ${theme.spacing(4.5)})`,
}));

export const StyledTableCellKey = styled(TableCell, { shouldForwardProp: prop => prop !== "isIndented" })<StyledTableCellKeyProps>(({ theme, isIndented = false }) => ({
    borderBottom: `1px solid ${theme.customColors.borderColor}`,
    padding: theme.spacing(1),
    paddingLeft: isIndented ? "12px" : 0,
    textAlign: 'left',
    textTransform: 'capitalize',
}));

export const StyledTableCellValue = styled(TableCell)(({ theme }) => ({
    borderBottom: `1px solid ${theme.customColors.borderColor}`,
    padding: theme.spacing(1, 0),
    textAlign: 'right',
    wordBreak: 'break-word',
}));

export const ExpandedExpandLess = styled(ExpandLess)(({ theme }) => ({
    transform: 'rotate(180deg)',
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
    })
})
)

export const CollapsedExpandLess = styled(ExpandLess)(({ theme }) => ({
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    })
}))

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
    borderRadius: 0,
    color: theme.customColors.black,
    height: 24,
    marginRight: theme.spacing(1),
    width: 24,
}))

export const SquaredIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.customColors.secondaryColor,
    borderRadius: 3,
    height: 30,
    marginLeft: theme.spacing(1),
    paddingBottom: 0,
    paddingTop: 0,
    width: 27,
}))

export const NoDataBox = styled(Box)(({ theme }) => ({
    "&:not(:first-of-type)": {
        display: "none",
    },
    fontSize: 14,
    fontWeight: "bold",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    textAlign: "left",
    textTransform: "capitalize",
}))

export const StyledRootStack = styled(Stack)(({ theme }) => ({
    backgroundColor: "white",
    borderRadius: 5,
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
        bottom: 8,
        left: 8,
        right: 8,
    },
    zIndex: 4,
}))

export const StyledCollapseAllButton = styled(Button)(({ theme }) => ({
    color: theme.customColors.primaryColor,
    paddingLeft: theme.spacing(2)
}))

export const StyledFeaturesBox = styled(Box)(({ theme }) => ({
    "&::-webkit-scrollbar-track": {
        borderLeft: "1px solid" + theme.customColors.borderColor + " !important",
        marginTop: 40,
    },
    backgroundColor: "white",
    minHeight: 100,
    minWidth: 300,
    overflowX: "hidden",
    overflowY: "scroll",
    paddingBottom: theme.spacing(1),
}))