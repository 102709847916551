import { ComponentProps, useRef } from "react"
import { MapIds } from "model/enums/MapIds"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { ScaleControl, useMap } from "@emblautec/react-map-gl"
import { fogStyle } from "constants/map/defaultFogStyle"
import { CoreProject } from "features/core/models/CoreProject"
import { getClientId, getProjectForClient, getProjectId } from "features/core/selectors"
import { getSelectedAppIsPublic } from "selectors/appsSelectors"
import { getBasemap, getLayouts, getMapLanguage, getPaints, getSources, getZoomRanges } from "selectors/mapSelectors"
import { getPrintFeatures } from "selectors/printSelectors"
import { useAppSelector } from "store/hooks/useAppSelector"
import { useAisLayers } from "utils/customHooks/map/useAisLayers"
import { useMapStyle } from "utils/customHooks/map/useMapStyle"
import { useOrderedMapLayers } from "utils/customHooks/map/useOrderedMapLayers"
import DigitizeLayers from "../components/DigitizeLayers/DigitizeLayers"
import HideAttribution from "../components/HideAttribution/HideAttribution"
import MapLanguage from "../components/MapLanguage/MapLanguage"
import CustomMap from "../CustomMap/CustomMap"
import TokenLoader from "../CustomMap/TokenLoader"
import MapCopyright from "../printMapExtraInfo/mapCopyright"
import MapDate from "../printMapExtraInfo/mapDate"
import MapHelper from "../printMapExtraInfo/mapHelper"
import MapLogo from "../printMapExtraInfo/mapLogo"
import MapNorthArrow from "../printMapExtraInfo/mapNorthArrow"
import MapNotes from "../printMapExtraInfo/mapNotes"
import MapTitle from "../printMapExtraInfo/mapTitle"
import { getMapOptions } from "../utils/getDefaultMapOptions"
import usePrintMapFilters from "./hooks/usePrintMapFilters"

type Params = {
    clientId: string
    projectId: string
}

const PrintMap = () => {
    const { mainMap } = useMap()
    const sources = useAppSelector(getSources)
    const paintsDict = useAppSelector(getPaints)
    const layoutsDict = useAppSelector(getLayouts)
    const zoomRangesDict = useAppSelector(getZoomRanges)
    const basemap = useAppSelector(getBasemap)
    const isPublic: boolean = useAppSelector(getSelectedAppIsPublic)

    const clientId = useAppSelector(getClientId)
    const projectId = useAppSelector(getProjectId)
    const mapLanguage = useAppSelector(getMapLanguage)
    const getAccessTokenRef = useRef<() => string>(() => "")

    const glMapOptions = getMapOptions(getAccessTokenRef, clientId, projectId)

    const params = useParams<Params>()
    const currentProject: CoreProject | undefined = useAppSelector(getProjectForClient(params))

    const {
        mapCopyright,
        mapDateFormat,
        mapNotes,
        mapTitle,
        showMapCopyright,
        showMapDate,
        showMapLegend,
        showMapLogo,
        showMapNorthArrow,
        showMapNotes,
        showMapScalebar,
        showMapTitle,
    } = useSelector(getPrintFeatures)

    const aisLayersData = useAisLayers()
    const layers = useOrderedMapLayers()
    const filters = usePrintMapFilters()

    const { mapStyle } = useMapStyle({
        basemap,
        extraData: {
            layers: [...layers, ...aisLayersData.aisLayers],
            layoutsDict: { ...layoutsDict, ...aisLayersData.aisLayoutsDict },
            paintsDict: { ...paintsDict, ...aisLayersData.aisPaintsDict },
            sources,
            zoomRangesDict: { ...zoomRangesDict, ...aisLayersData.aisZoomRangesDict },
            isPublic: isPublic ?? false,
        },
    })

    const mapProps: ComponentProps<typeof CustomMap> = {
        id: MapIds.PrintMap,
        ...glMapOptions,
        aisLayersData,
        filters: filters,
        fog: fogStyle,
        initialViewState: {
            // @ts-ignore
            bounds: mainMap?.getBounds(),
        },
        layers,
        layoutsDict,
        mapStyle,
        paintsDict,
        sources,
        zoomRangesDict,
        isPublic: isPublic ?? false,
    }

    return (
        <>
            {isPublic !== undefined && !isPublic && <TokenLoader getAccessTokenRef={getAccessTokenRef} />}

            <CustomMap {...mapProps}>
                <DigitizeLayers />
                <HideAttribution mapId={MapIds.PrintMap} />
                {showMapTitle && !showMapLegend && <MapTitle mapTitle={mapTitle} />}
                {showMapNotes && !showMapLegend && <MapNotes mapNotes={mapNotes} />}
                {showMapCopyright && !showMapLegend && (
                    <MapCopyright mapCopyright={mapCopyright} showMapLegend={showMapLegend} />
                )}
                {showMapDate && !showMapLegend && (
                    <MapDate mapDateFormat={mapDateFormat} showMapLegend={showMapLegend} />
                )}
                {showMapLogo && !!currentProject?.logoUrl && <MapLogo logoSrc={currentProject.logoUrl} />}
                {showMapNorthArrow && <MapNorthArrow />}
                {showMapScalebar && <ScaleControl position="bottom-right" />}
                <MapLanguage language={mapLanguage} />
                <MapHelper />
            </CustomMap>
        </>
    )
}

export default PrintMap
