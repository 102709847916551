import { FC } from "react"
import { useParams, useRouteMatch } from "react-router-dom"
import config from "config"
import { BufferWWContextProvider } from "features/buffer/components/BufferWWContextProvider"
import MapView from "views/MapView"

const MainPublicView: FC = () => {
    const { appId } = useParams<{ appId: string }>()
    const { path } = useRouteMatch()

    return (
        <BufferWWContextProvider appId={appId} maxNumberOfWorkers={config.bufferOptions.maxNumberOfWebWorkers}>
            <MapView path={path} />
        </BufferWWContextProvider>
    )
}

export default MainPublicView
