import React, { MouseEvent } from "react"
import { Checkbox } from "@mui/material"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import { TruncatedTypography } from "@windgis/shared"
import Linkify from "react-linkify"
import { DatasetProperties } from "features/datasets/models/DatasetProperties"
import toastr from "utils/customToastr"
import useAttributeRowStyles from "./styles"

type Props = {
    key: string
    feature: Omit<DatasetProperties, "bounding_box">
    label: string
    isChecked: boolean
    isDownloadable: boolean
    onFeatureCheckToggle: () => void
    onRowClick: () => void
}

const AttributeRow = ({ feature, label, isChecked, isDownloadable, onFeatureCheckToggle, onRowClick }: Props) => {
    const classes = useAttributeRowStyles()

    const onUrlClick = (e: React.MouseEvent<HTMLAnchorElement> | undefined, url: string | number) => {
        if (typeof url === "string" && url.includes("http")) {
            e?.stopPropagation()
            toastr.info("URL copied to clipboard.")
            navigator.clipboard.writeText(url)
        }
    }

    const onClick = (e: MouseEvent<HTMLTableRowElement>) => {
        e.stopPropagation()
        onRowClick()
    }

    const onCheckBoxClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        onFeatureCheckToggle()
    }

    return (
        <TableRow
            aria-checked={isChecked}
            data-testid={attributeRowTestId}
            hover
            selected={isChecked}
            tabIndex={-1}
            onClick={onClick}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    checked={isChecked}
                    data-testid={checkboxTestId}
                    inputProps={{
                        "aria-labelledby": label,
                    }}
                    disabled={!isDownloadable}
                    onClick={onCheckBoxClick}
                />
            </TableCell>
            {Object.keys(feature).map(featureName => (
                <TableCell
                    key={featureName}
                    align={typeof feature[featureName] === "number" ? "right" : "left"}
                    className={classes.cell}
                >
                    <TruncatedTypography variant="body1">
                        <Linkify
                            componentDecorator={(_, decoratedText, key) => (
                                <a
                                    key={key}
                                    data-testid={linkTestId}
                                    href="javascript:;"
                                    onClick={e => onUrlClick(e, feature[featureName])}
                                >
                                    {decoratedText}
                                </a>
                            )}
                        >
                            {feature[featureName]}
                        </Linkify>
                    </TruncatedTypography>
                </TableCell>
            ))}
        </TableRow>
    )
}

export default AttributeRow

const attributeRowTestId = "qa-attribute-table-row"
const checkboxTestId = "qa-attribute-table-checkbox"
const linkTestId = "qa-attribute-table-link"
