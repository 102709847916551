import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { availableURLSearchParams, useSearchParams } from "@windgis/shared"
import dayjs, { Dayjs } from "dayjs"
import { Subscription } from "model/ais/Subscription"
import { ArchiveMetadata } from "features/ais/models/ArchiveMetadata"
import { PlaybackSpeed } from "features/ais/models/PlaybackSpeed"
import { setIsPlaybackPlaying, setSelectedArchiveSubscriptionId, updateBoatDetails } from "reducers/ais"
import { getSelectedArchiveSubscriptionId } from "selectors/aisSelectors"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import { useAppSelector } from "store/hooks/useAppSelector"
import { getSecondsBetweenBuckets, PLAYBACK_SPEED_SETTINGS_DICTIONARY } from "../utils"
import useAisArchiveData from "./useAisArchiveData"
import useArchiveAnimations from "./useArchiveAnimations"

const RESUME_AFTER_JUMP_TIMEOUT = 300

type Args = {
    archiveMetadata: ArchiveMetadata
    filteringInterval: [Dayjs, Dayjs]
    subscription: Subscription
}

const useArchiveAnimationsManager = ({ archiveMetadata, filteringInterval, subscription }: Args) => {
    const [targetDate, setTargetDate] = useState<Dayjs | null>(null)
    const [playbackSpeed, setPlaybackSpeed] = useState(PlaybackSpeed["1x"])
    const [startDate, setStartDate] = useState<Dayjs>(filteringInterval[0])
    const [endDate, setEndDate] = useState<Dayjs>(filteringInterval[1])
    const dispatch = useAppDispatch()

    const [currentDate, setCurrentDate] = useState<Dayjs>(startDate.second(0).millisecond(0))

    const [shouldMoveToNextChunk, setShouldMoveToNextChunk] = useState(false)
    const [shouldResumePlay, setShouldResumePlay] = useState(false)
    const [shouldJumpToDate, setShouldJumpToDate] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const [shouldShowReplay, setShouldShowReplay] = useState(false)

    const [overrideStartDate, setOverrideStartDate] = useState<Dayjs | null>(null)
    const overrideSkipTransitionRef = useRef<boolean>(false)

    const timeoutRef = useRef<NodeJS.Timeout | null>(null)
    const resumeAfterJumpTimeoutRef = useRef<NodeJS.Timeout | null>(null)

    const selectedArchiveSubscriptionId = useAppSelector(getSelectedArchiveSubscriptionId)

    const { getSearchParamValue, updateSearchParams } = useSearchParams()

    const pausePlaybackFromSearchParams = getSearchParamValue(availableURLSearchParams.pausePlayback.key) === "true"
    const followedVesselId = getSearchParamValue(availableURLSearchParams.selectedVesselId.key)

    const { data, goToChunkHavingBucketWithIndex, goToNextChunk, hasNextChunk, isFetching, isNextChunkFetching } =
        useAisArchiveData({
            averageResponsesPerMinute:
                archiveMetadata.averagePositionsPerUpdate > 0 ? archiveMetadata.averagePositionsPerUpdate : 1,
            bucketSizeInMinutes: PLAYBACK_SPEED_SETTINGS_DICTIONARY[playbackSpeed].bucketSizeInMinutes,
            endDate,
            filterInterval: filteringInterval,
            interpolationFactor: PLAYBACK_SPEED_SETTINGS_DICTIONARY[playbackSpeed].interpolationFactor,
            lookAheadInMinutes: PLAYBACK_SPEED_SETTINGS_DICTIONARY[playbackSpeed].lookAheadInMinutes,
            lookBehindInMinutes: subscription.maxBoatAge,
            overrideStartDate,
            subscriptionId: subscription.id,
            startDate,
        })

    const buckets = useMemo(() => data?.buckets ?? [], [data])
    const boatDetails = useMemo(() => data?.boatDetails ?? [], [data])

    const { animate, currentBucketIndexRef, jumpToDate, stopAnimation } = useArchiveAnimations(
        subscription,
        buckets,
        PLAYBACK_SPEED_SETTINGS_DICTIONARY[playbackSpeed].intervalBetweenLayersInMs,
        filteringInterval,
    )

    const play = useCallback(() => {
        if (isFetching || !data || !currentDate) return

        setCurrentDate(buckets[currentBucketIndexRef.current]?.startDate ?? null)

        if (currentBucketIndexRef.current === buckets.length - 1) {
            animate(overrideSkipTransitionRef.current)
            overrideSkipTransitionRef.current = false

            if (hasNextChunk) setShouldMoveToNextChunk(true)
            else {
                setShouldShowReplay(true)
                setIsPlaying(false)
            }

            return
        }

        setShouldShowReplay(false)
        animate(overrideSkipTransitionRef.current)
        overrideSkipTransitionRef.current = false
        timeoutRef.current = setTimeout(
            play,
            PLAYBACK_SPEED_SETTINGS_DICTIONARY[playbackSpeed].intervalBetweenLayersInMs,
        )
    }, [animate, buckets, currentBucketIndexRef, data, hasNextChunk, isFetching, playbackSpeed])

    const pausePlayback = useCallback(() => {
        setIsPlaying(false)
        if (timeoutRef.current) clearTimeout(timeoutRef.current)
        timeoutRef.current = null
    }, [])

    const startPlayback = useCallback(() => {
        if (isPlaying) return

        updateSearchParams({ remove: [availableURLSearchParams.pausePlayback.key] })
        setIsPlaying(true)
        setShouldResumePlay(true)
        dispatch(setSelectedArchiveSubscriptionId(subscription.id))
    }, [isPlaying, updateSearchParams])

    const stopPlayback = useCallback(() => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current)
        if (resumeAfterJumpTimeoutRef.current) clearTimeout(resumeAfterJumpTimeoutRef.current)
        stopAnimation()
        if (selectedArchiveSubscriptionId !== subscription.id) dispatch(setSelectedArchiveSubscriptionId(null))
    }, [stopAnimation])

    const jumpToBucket = useCallback(
        (bucketIndex: number) => {
            setOverrideStartDate(null)
            overrideSkipTransitionRef.current = false

            if (resumeAfterJumpTimeoutRef.current) clearTimeout(resumeAfterJumpTimeoutRef.current)
            if (timeoutRef.current) clearTimeout(timeoutRef.current)

            const secondsBetweenBuckets = getSecondsBetweenBuckets(
                PLAYBACK_SPEED_SETTINGS_DICTIONARY[playbackSpeed].bucketSizeInMinutes,
                PLAYBACK_SPEED_SETTINGS_DICTIONARY[playbackSpeed].interpolationFactor,
            )

            goToChunkHavingBucketWithIndex(bucketIndex)
            const targetTime = startDate.add(bucketIndex * secondsBetweenBuckets, "seconds")
            setTargetDate(startDate.add(bucketIndex * secondsBetweenBuckets, "seconds"))
            setCurrentDate(targetTime)
            setShouldJumpToDate(true)
            setShouldShowReplay(false)

            if (isPlaying)
                resumeAfterJumpTimeoutRef.current = setTimeout(
                    () => setShouldResumePlay(true),
                    RESUME_AFTER_JUMP_TIMEOUT,
                )
        },
        [goToChunkHavingBucketWithIndex, isPlaying, startDate, playbackSpeed],
    )

    const changePlaybackSpeed = useCallback(
        (newSpeed: PlaybackSpeed) => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current)
            timeoutRef.current = null

            overrideSkipTransitionRef.current = true
            currentBucketIndexRef.current = 0
            setPlaybackSpeed(newSpeed)
            setOverrideStartDate(dayjs(currentDate))

            if (isPlaying) setShouldResumePlay(true)
        },
        [currentBucketIndexRef, currentDate, isPlaying],
    )

    // handle jumps
    // if we have the data we are jumping to available,
    // the jumpToDate call will also render the boats, acting as
    // a preview even if the user is dragging the cursor on the timeline
    useEffect(() => {
        if (shouldJumpToDate && !isFetching && targetDate !== null) {
            if (targetDate.isSame(endDate)) setShouldShowReplay(true)

            jumpToDate(targetDate)
            setTargetDate(null)
            setShouldJumpToDate(false)
        }
    }, [endDate, isFetching, jumpToDate, shouldJumpToDate, targetDate])

    // handle resume play when changing between chunks
    useEffect(() => {
        if (shouldMoveToNextChunk && hasNextChunk && !isNextChunkFetching) {
            goToNextChunk()
            setShouldMoveToNextChunk(false)
            setOverrideStartDate(null)
            setShouldResumePlay(true)
        }
    }, [goToNextChunk, hasNextChunk, isNextChunkFetching, shouldMoveToNextChunk])

    // handle resume play
    // the timeout is used in order to wait for
    // the current animation to finish before
    // updating the layer
    useEffect(() => {
        if (shouldResumePlay && !isFetching && !shouldJumpToDate) {
            setShouldResumePlay(false)
            timeoutRef.current = setTimeout(
                () => play(),
                PLAYBACK_SPEED_SETTINGS_DICTIONARY[playbackSpeed].intervalBetweenLayersInMs,
            )
        }
    }, [isFetching, play, playbackSpeed, shouldJumpToDate, shouldResumePlay])

    useEffect(() => {
        if (boatDetails) dispatch(updateBoatDetails(boatDetails))
    }, [boatDetails, dispatch])

    // handle the filtering
    useEffect(() => {
        if (resumeAfterJumpTimeoutRef.current) {
            clearTimeout(resumeAfterJumpTimeoutRef.current)
        }

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }

        setStartDate(filteringInterval[0])
        setEndDate(filteringInterval[1])
        setCurrentDate(filteringInterval[0])

        if (isPlaying) {
            resumeAfterJumpTimeoutRef.current = setTimeout(() => setShouldResumePlay(true), RESUME_AFTER_JUMP_TIMEOUT)
        }
    }, [filteringInterval])

    useEffect(() => {
        dispatch(setIsPlaybackPlaying(isPlaying))
    }, [isPlaying])

    useEffect(() => {
        if (pausePlaybackFromSearchParams) {
            pausePlayback()
        } else {
            followedVesselId && startPlayback()
        }
    }, [pausePlaybackFromSearchParams, followedVesselId])

    return {
        changePlaybackSpeed,
        currentDate,
        endDate,
        jumpToBucket,
        playbackSpeed,
        isFetching,
        isPlaying,
        shouldShowReplay,
        pausePlayback,
        startDate,
        startPlayback,
        stopPlayback,
    }
}

export default useArchiveAnimationsManager
