import React, { Component } from "react"
import AddIcon from "@mui/icons-material/Add"
import { Tooltip } from "@mui/material"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { PropertyStyleTypes } from "../constants/propertyStyleTypes"
import { withStylerContext } from "../HOCs/withStylerContext"
import { columnTypeIsNumber } from "../utils/helpers/columnTypeIsNumber"
import { isColorProperty } from "../utils/helpers/isColorProperty"
import { transformStringToFloatNumber } from "../utils/transformers/transformStringToFloatNumber"
import CategoriseModal from "./categoriseModal"
import ColorDragAndDrop from "./ColorsDragAndDrop/ColorDragAndDrop"
import GraduateModal from "./graduateModal"
import BooleanProperty from "./styleProperties/booleanProperty"
import ColorArrayProperty from "./styleProperties/colorArrayProperty"
import ColorProperty from "./styleProperties/colorProperty/colorProperty"
import ColumnProperty from "./styleProperties/columnProperty"
import LineDasharrayProperty from "./styleProperties/CustomPropertyComponents/LineDasharrayProperty/LineDasharrayProperty"
import TextOffsetProperty from "./styleProperties/CustomPropertyComponents/TextOffsetProperty/TextOffsetProperty"
import IconProperty from "./styleProperties/iconProperty"
import NumberArrayProperty from "./styleProperties/numberArrayProperty"
import FloatProperty from "./styleProperties/numberProperty/floatProperty"
import NumberProperty from "./styleProperties/numberProperty/numberProperty"
import SelectProperty from "./styleProperties/selectProperty"
import AutocompleteProperty from "./styleProperties/autocompleteProperty"
import TextProperty from "./styleProperties/textProperty"
import StylePropertyOptions from "./StylePropertyOptions/StylePropertyOptions"
import { getIsLayerTypeRaster } from "@windgis/shared"

let transformDict = {
    boolean: x => x,
    color: x => x,
    "color-array": x => x,
    column: x => x,
    float: x => parseFloat(x),
    icon: x => x,
    "multi-color": x => x,
    number: x => parseInt(x),
    numberArray: x => x,
    select: x => x,
    "string-array": x => x,
    text: x => x,
}

class StyleProperty extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categoriseDialogOpen: false,
            graduateModalOpen: false,
        }
        this.onPropertyValueChanged = this.onPropertyValueChanged.bind(this)
        this.onSpecificPropertyValueChanged = this.onSpecificPropertyValueChanged.bind(this)
        this.onPropertyTitleChanged = this.onPropertyTitleChanged.bind(this)
        this.onExpressionPropertyChanged = this.onExpressionPropertyChanged.bind(this)
        this.onRemoveGraduateColorProperty = this.onRemoveGraduateColorProperty.bind(this)
        this.onAddColorRow = this.onAddColorRow.bind(this)
        this.onRemoveColorRow = this.onRemoveColorRow.bind(this)
    }

    onSwitchToCategorised(matchArray) {
        this.props.stylerContext.onPropertyChanged(this.props.styleId, {
            ...this.props.property,
            expressionType: "match",
            value: matchArray,
        })
    }

    onSwitchToGraduated(interpolateArray) {
        this.props.stylerContext.onPropertyChanged(this.props.styleId, {
            ...this.props.property,
            expressionType: "interpolate",
            value: interpolateArray,
        })
    }

    defaultZoomInterpolateArray(defaultValue) {
        const interpolateArray = ["interpolate", ["linear"], ["zoom"]]
        const { initSharedExpression } = this.props.stylerContext

        const keysArray = [5, 18]

        switch (this.props.property.name) {
            case "text-offset":
            case "line-dasharray":
                keysArray.forEach(k => interpolateArray.push(k, ["literal", defaultValue]))
                break
            default:
                keysArray.forEach(k => interpolateArray.push(k, defaultValue))
        }
        initSharedExpression(keysArray, PropertyStyleTypes.ZoomDependent)
        return interpolateArray
    }

    // only colors will get to this point
    zoomInterpolateArrayFromKeys(defaultValue) {
        const interpolateArray = ["interpolate", ["linear"], ["zoom"]]
        const { keysArray } = this.props.stylerContext

        keysArray.forEach(k => {
            interpolateArray.push(k, defaultValue)
        })
        return interpolateArray
    }

    dragAndDrop(array, fromIndex, toIndex) {
        const newArray = array.slice()
        const elements = newArray.splice(fromIndex, 2)
        newArray.splice(toIndex, 0, ...elements)

        return newArray
    }

    onZoomDependentClick() {
        const { sharedExpressionType } = this.props.stylerContext
        const isColor = isColorProperty(this.props.property)
        const defaultValue = this.props.stylerContext.styleConfig[this.props.layerType].find(
            x => x.name === this.props.property.name,
        ).value

        if (
            isColor &&
            sharedExpressionType === PropertyStyleTypes.ZoomDependent &&
            this.props.property.expressionType === "none"
        ) {
            var interpolateArray = this.zoomInterpolateArrayFromKeys(defaultValue)
        } else {
            var interpolateArray = this.defaultZoomInterpolateArray(defaultValue)
        }

        this.props.stylerContext.onPropertyChanged(this.props.styleId, {
            ...this.props.property,
            expressionType: "interpolate",
            value: interpolateArray,
        })
    }

    onReset(property) {
        const config = this.props.stylerContext.styleConfig[this.props.layerType].find(x => x.name === property.name)

        this.props.stylerContext.onPropertyChanged(this.props.styleId, {
            ...this.props.property,
            expressionType: "none",
            value: config.value,
        })
    }

    renderProperty(property) {
        switch (property.expressionType) {
            case "none":
                return this.renderNoExpression(property)
            case "match":
                return this.renderMatchExpression(property)
            case "interpolate":
                return this.renderInterpolateExpression(property)
            default:
                return this.renderNoExpression(property)
        }
    }

    renderNoExpression(property) {
        const propertyProps = {
            title: property.title,
            value: property.value,
            onPropertyChanged: this.onPropertyValueChanged,
            onSpecificPropertyChanged: this.onSpecificPropertyValueChanged,
        }

        const columns = this.props.stylerContext.columns

        const propertyNameDictionary = {
            "line-dasharray": <LineDasharrayProperty {...propertyProps} />,
            "text-offset": <TextOffsetProperty {...propertyProps} />,
        }

        const propertyTypeDictionary = {
            color: <ColorProperty {...propertyProps} />,
            number: <NumberProperty min={property.min} max={property.max} {...propertyProps} />,
            float: <FloatProperty min={property.min} max={property.max} {...propertyProps} />,
            "color-array": this.props.properties.some(x => x.name.endsWith("labels")) ? (
                <ColorArrayProperty
                    {...propertyProps}
                    labels={this.props.properties.find(x => x.name.endsWith("labels")).value}
                    onAdd={this.onAddColorRow}
                    onRemove={this.onRemoveColorRow}
                    editable={!this.props.stylerContext.isDprLayer && !getIsLayerTypeRaster(this.props.layerType)}
                />
            ) : undefined,
            numberArray: <NumberArrayProperty {...propertyProps} />,
            text:
                columns.length > 0 ? (
                    <AutocompleteProperty {...propertyProps} columns={columns} />
                ) : (
                    <TextProperty {...propertyProps} />
                ),
            select: <SelectProperty options={property.options} {...propertyProps} />,
            boolean: <BooleanProperty {...propertyProps} />,
            icon: <IconProperty {...propertyProps} />,
            column: <ColumnProperty {...propertyProps} columns={columns} />,
        }
        const propertyToRender = propertyNameDictionary[property.name] ?? propertyTypeDictionary[property.propertyType]

        if (!propertyToRender) {
            console.error("Invalid property type")
            return null
        }

        return propertyToRender
    }

    onPropertyValueChanged(value) {
        this.props.onPropertyChanged(this.props.property, value)
    }

    onSpecificPropertyValueChanged(property, value) {
        this.props.onPropertyChanged(property, value)
    }

    onAddColorRow() {
        this.props.onAddColorRow()
    }

    onRemoveColorRow(index) {
        this.props.onRemoveColorRow(index)
    }

    getMatchPropertyCaseType = () => {
        if (getIsLayerTypeRaster(this.props.layerType)) {
            return "string"
        }

        const columnName = this.props.property.value[1][1]
        const column = this.props.stylerContext.columns.find(x => x.prettyName === columnName)

        return columnTypeIsNumber(column.type.toLowerCase()) ? "number" : "string"
    }

    transformStringTitleBasedOnExpression(stringNewTitle) {
        const { property } = this.props
        switch (property.expressionType) {
            case "interpolate":
                return transformStringToFloatNumber(stringNewTitle)
            case "match":
                const matchPropertyCaseType = this.getMatchPropertyCaseType()
                const isNumberProperty = matchPropertyCaseType === "number"
                return isNumberProperty ? parseFloat(stringNewTitle) : stringNewTitle
            default:
                return stringNewTitle
        }
    }

    onPropertyTitleChanged(stringNewTitle, keyIndex, expressionArrayIndex) {
        const { property } = this.props
        const { updateExpressionKey } = this.props.stylerContext

        const newTitle = this.transformStringTitleBasedOnExpression(stringNewTitle)

        if (isColorProperty(property)) {
            updateExpressionKey(keyIndex, newTitle)
            return
        }

        const newPropertyValue = [...property.value]

        newPropertyValue[expressionArrayIndex] = newTitle

        this.props.onPropertyChanged(this.props.property, newPropertyValue)
    }

    renderInterpolateExpression(property) {
        const { max, min, name, options, propertyType, value } = property
        const properties = []

        let propertyIndex = 0
        const offset = 3 // interpolateValuesOffset

        for (let i = offset; i < value.length - 1; i += 2) {
            properties.push({
                expressionArrayIndex: offset + 2 * propertyIndex,
                keyIndex: propertyIndex++,
                max,
                min,
                name,
                title: value[i],
                value: value[i + 1],
            })
        }
        const onDragEnd = result => {
            let valueArray = [...property.value]

            const to = result.destination.index * 2 + offset
            const from = Number(result.draggableId)
            valueArray = this.dragAndDrop(valueArray, from, to)

            this.onPropertyValueChanged(valueArray)
        }

        const onDragColorEnd = result => {
            let valueArray = [...property.value]

            const to = (result.destination.index + 2) * 2
            const from = (Number(result.source.index) + 2) * 2

            if (to >= valueArray.length) return

            valueArray = this.dragAndDropColor(valueArray, from, to)

            this.onPropertyValueChanged(valueArray)
        }

        const isZoomDependent = value?.[2]?.[0] === "zoom"
        return propertyType !== "color" ? (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={"styleValues"} type={"styleValues"}>
                    {provided => (
                        <div className="values" {...provided.droppableProps} ref={provided.innerRef}>
                            {properties.map((prop, index) => (
                                <Draggable
                                    key={prop.keyIndex}
                                    draggableId={String(prop.expressionArrayIndex)}
                                    index={index}
                                >
                                    {provided => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            {this.renderExpressionValue(propertyType, prop, options, {
                                                isLastRemainingCase: properties.length === 1,
                                                isZoomDependent,
                                                onlyTwoInterpolatedValuesRemaining: propertyIndex === 2,
                                            })}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        ) : (
            <ColorDragAndDrop
                interpolate={true}
                layerType={this.props.layerType}
                hasCpt={this.props.stylerContext.hasCpt}
                properties={properties}
                property={property}
                propertyIndex={propertyIndex}
                isZoomDependent={isZoomDependent}
                onExpressionPropertyChanged={this.onExpressionPropertyChanged}
                onPropertyTitleChanged={this.onPropertyTitleChanged}
                onPropertyValueChanged={this.onPropertyValueChanged}
                onRemoveGraduateColorProperty={this.onRemoveGraduateColorProperty}
            />
        )
    }

    dragAndDropColor(array, fromIndex, toIndex) {
        const newArray = [...array]

        if (fromIndex === null || toIndex === null) return newArray

        if (fromIndex > toIndex) {
            for (let i = toIndex; i <= fromIndex - 2; i += 2) {
                newArray[i + 2] = array[i]
            }
        } else {
            for (let i = toIndex; i >= fromIndex + 2; i -= 2) {
                newArray[i - 2] = array[i]
            }
        }
        newArray[toIndex] = array[fromIndex]

        return newArray
    }

    renderMatchExpression(property) {
        const { max, min, name, options, propertyType, value } = property
        let properties = []

        let propertyIndex = 0
        const offset = 2 // matchValuesOffset

        for (let i = offset; i < value.length - 1; i += 2) {
            properties.push({
                expressionArrayIndex: offset + 2 * propertyIndex,
                keyIndex: propertyIndex++,
                max,
                min,
                name,
                title: value[i],
                value: value[i + 1],
            })
        }

        //We don't want to render the default value since it's not displayed in the legend for rasters
        if (!getIsLayerTypeRaster(this.props.layerType)) {
            properties.push({
                expressionArrayIndex: value.length - 1,
                keyIndex: propertyIndex,
                max,
                min,
                name,
                title: "Default",
                value: value[value.length - 1],
            })
        }

        const isZoomDependent = value?.[2]?.[0] === "zoom"

        //there needs to be at least one property + the default property
        const numOfPropertiesWhenLastCase = 2
        const onDragEnd = result => {
            let valueArray = [...property.value]

            const to = result.destination.index * 2 + offset
            const from = Number(result.draggableId)
            valueArray = this.dragAndDrop(valueArray, from, to)

            this.onPropertyValueChanged(valueArray)
        }

        const onDragColorEnd = result => {
            let valueArray = [...property.value]

            const to = result.destination.index * 2 + 1
            const from = Number(result.source.index) * 2 + 1

            if (to >= valueArray.length) return

            valueArray = this.dragAndDropColor(valueArray, from, to)

            this.onPropertyValueChanged(valueArray)
        }

        return propertyType !== "color" ? (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={"styleValues"} type={"styleValues"}>
                    {provided => (
                        <div className="values" {...provided.droppableProps} ref={provided.innerRef}>
                            {properties.map((prop, index) => {
                                const isDefaultValue =
                                    !getIsLayerTypeRaster(this.props.layerType) && index === properties.length - 1

                                return (
                                    <Draggable
                                        key={"draggable" + prop.keyIndex}
                                        draggableId={String(prop.expressionArrayIndex)}
                                        index={index}
                                        isDragDisabled={isDefaultValue || getIsLayerTypeRaster(this.props.layerType)}
                                    >
                                        {provided => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                {this.renderExpressionValue(propertyType, prop, options, {
                                                    isDefaultValue,
                                                    isLastRemainingCase:
                                                        !getIsLayerTypeRaster(this.props.layerType) &&
                                                        properties.length === numOfPropertiesWhenLastCase,
                                                    isZoomDependent,
                                                })}
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        ) : (
            <ColorDragAndDrop
                interpolate={false}
                layerType={this.props.layerType}
                hasCpt={this.props.stylerContext.hasCpt}
                numOfPropertiesWhenLastCase={numOfPropertiesWhenLastCase}
                properties={properties}
                property={property}
                propertyIndex={propertyIndex}
                isZoomDependent={isZoomDependent}
                onExpressionPropertyChanged={this.onExpressionPropertyChanged}
                onPropertyTitleChanged={this.onPropertyTitleChanged}
                onPropertyValueChanged={this.onPropertyValueChanged}
                onRemoveGraduateColorProperty={this.onRemoveGraduateColorProperty}
            />
        )
    }

    renderExpressionValue(type, property, options, flags) {
        const { isDefaultValue, isLastRemainingCase, isZoomDependent, onlyTwoInterpolatedValuesRemaining } = flags
        const { expressionArrayIndex, keyIndex, max, min, name, title, value } = property
        const propertyProps = {
            key: expressionArrayIndex,
            editable: true,
            title,
            value,
            isDefaultValue,
            isLastRemainingCase,
            isZoomDependent,
            onPropertyChanged: value =>
                this.onExpressionPropertyChanged(expressionArrayIndex, type, value, isDefaultValue),
            onTitleChanged: newTitle => this.onPropertyTitleChanged(newTitle, keyIndex, expressionArrayIndex),
        }

        const columns = this.props.stylerContext.columns

        const notRemovable = onlyTwoInterpolatedValuesRemaining || isLastRemainingCase

        const propertyNameDictionary = {
            "line-dasharray": <LineDasharrayProperty {...propertyProps} />,
            "text-offset": <TextOffsetProperty {...propertyProps} />,
        }

        const propertyTypeDictionary = {
            color: (
                <ColorProperty
                    onRemove={!notRemovable && (() => this.onRemoveGraduateColorProperty(keyIndex))}
                    {...propertyProps}
                />
            ),
            number: <NumberProperty max={max} min={min} {...propertyProps} />,
            numberArray: <NumberArrayProperty {...propertyProps} />,
            float: <FloatProperty max={max} min={min} {...propertyProps} />,
            text:
                columns.length > 0 ? (
                    <AutocompleteProperty {...propertyProps} columns={columns} />
                ) : (
                    <TextProperty {...propertyProps} />
                ),
            select: <SelectProperty options={options} {...propertyProps} />,
            boolean: <BooleanProperty {...propertyProps} />,
            icon: <IconProperty {...propertyProps} />,
            column: <ColumnProperty {...propertyProps} columns={columns} />,
        }

        const propertyToRender = propertyNameDictionary[name] ?? propertyTypeDictionary[type]

        if (!propertyToRender) {
            console.error("Invalid property type")
            return null
        }

        return propertyToRender
    }

    onExpressionPropertyChanged(index, type, value, isDefaultValue) {
        const newPropValues = [...this.props.property.value]
        const indexOfValue = isDefaultValue ? index : index + 1
        newPropValues[indexOfValue] = transformDict[type](value)
        this.props.onPropertyChanged(this.props.property, newPropValues)
    }

    onAddCategoriseRow() {
        const layerType = this.props.layerType

        const property = this.props.property
        const newPropertyValue = [...property.value]

        let newMatchCase

        if (getIsLayerTypeRaster(layerType)) {
            newMatchCase = "New case, try to edit me"
        } else {
            const matchPropertyCaseType = this.getMatchPropertyCaseType()

            if (matchPropertyCaseType === "number") {
                newMatchCase = 0
            } else {
                newMatchCase = "New case, try to edit me"
            }
        }

        if (isColorProperty(property)) {
            this.props.stylerContext.addExpressionKey(newMatchCase)
            return
        }
        //Start from position 2 to skip match and get parameters
        newPropertyValue.splice(2, 0, ...[newMatchCase, newPropertyValue[newPropertyValue.length - 1]])

        this.props.onPropertyChanged(property, newPropertyValue)
    }

    onRemoveGraduateColorProperty(keyIndex) {
        this.props.stylerContext.removeExpressionKey(keyIndex)
    }

    onCategoriseClick() {
        this.setState({
            categoriseDialogOpen: true,
        })
    }

    onCategoriseDialogClose() {
        this.setState({
            categoriseDialogOpen: false,
        })
    }

    onGraduateClick() {
        const { columns, selectedDatasetColumn, setSelectedDatasetColumn } = this.props.stylerContext
        if (selectedDatasetColumn && !columnTypeIsNumber(selectedDatasetColumn.type.toLowerCase())) {
            const firstValidColumn = columns.find(col => columnTypeIsNumber(col.type.toLowerCase()))
            setSelectedDatasetColumn(firstValidColumn || null)
        }
        this.setState({
            graduateModalOpen: true,
        })
    }

    onGraduateDialogClose() {
        this.setState({
            graduateModalOpen: false,
        })
    }

    render() {
        const { layerType, property } = this.props

        const values = this.renderProperty(property)

        return (
            <div className="style-property">
                <StylePropertyOptions
                    layerType={layerType}
                    property={property}
                    onCategorizeClick={this.onCategoriseClick.bind(this)}
                    onGraduateClick={this.onGraduateClick.bind(this)}
                    onResetClick={this.onReset.bind(this)}
                    onZoomDependentClick={this.onZoomDependentClick.bind(this)}
                />

                {property.expressionType === "match" && !this.props.stylerContext.hasCpt && (
                    <div className="list-actions">
                        <Tooltip title="Add Row">
                            <AddIcon className="action" onClick={() => this.onAddCategoriseRow()} />
                        </Tooltip>
                    </div>
                )}

                {values}

                <CategoriseModal
                    open={this.state.categoriseDialogOpen}
                    property={property}
                    onClose={this.onCategoriseDialogClose.bind(this)}
                    onFinish={this.onSwitchToCategorised.bind(this)}
                />
                <GraduateModal
                    open={this.state.graduateModalOpen}
                    property={property}
                    onClose={this.onGraduateDialogClose.bind(this)}
                    onFinish={this.onSwitchToGraduated.bind(this)}
                />
            </div>
        )
    }
}

export default withStylerContext(StyleProperty)
