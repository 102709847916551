import dayjs, { Dayjs } from "dayjs"
import { INVALID_DATE_STRING, DATE_REGEX } from "../components/sidebar/ais/utils"
import { DATE_FORMAT_AIS } from "../constants/date"

export const excludedProperties = [
    "ogc_fid",
    "id",
    "subscriptionId",
    "gid",
    "geom_type",
    "?column?",
    "icon-rotate",
    "text-field",
    "icon-opacity",
    "icon-image",
]

// regex to clean the property names that contain "_" and to add space between the camel case names
export const generatePropertyName = (propertyName: string) =>
    propertyName
        .replace(/_+/g, " ")
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .trim()

const formatDate = (date?: string | Dayjs): string => {
    if (!date) return ""

    const utcDate = dayjs.utc(date)

    if (!utcDate.isValid()) {
        return typeof date === "string" ? date : date.toString()
    }

    return utcDate.format(DATE_FORMAT_AIS).toString()
}

export const getRenderedPropertyValue = (value: string | Dayjs | number): string => {
    // if the value is a date, format it
    if (dayjs.isDayjs(value)) {
        return formatDate(value)
    }

    // if the value is a string, check if it is a date and format it
    if (typeof value === "string") {
        return DATE_REGEX.test(value) ? formatDate(value) : value
    }

    return value.toString()
}

export const isValidProperty = (property: [string, string | Dayjs | number]) => {
    return (
        property[0] !== "startTime" &&
        property[0] !== "time" &&
        property[0] !== "createdAt" &&
        property[1] !== null &&
        property[1] !== undefined &&
        property[1] !== "" &&
        property[1] !== INVALID_DATE_STRING
    )
}
