import { useState } from "react"
import { ID_COLUMN } from "@windgis/shared"
import useInfoboxToUse from "components/map/infoBoxes/DprProperties/useInfoboxToUse"
import {
    GetDatasetPropertiesResult,
    useFetchDatasetPropertiesQuery,
    useFetchDatasetRowCountQuery,
} from "features/datasets/api"
import { useFetchDprDatasetColumnsQuery } from "features/dprIntegration/api"
import { getSelectedAppIsPublic } from "selectors/appsSelectors"
import { useAppSelector } from "store/hooks/useAppSelector"

const useAttributeTableData = (datasetId: string) => {
    const [text, setText] = useState("")
    const [rows, setRows] = useState(10)
    const [page, setPage] = useState(0)
    const [order, setOrder] = useState(ID_COLUMN)
    const [desc, setDesc] = useState(false)

    const { data, error, refetch, isError, isFetching } = useFetchDatasetPropertiesQuery({
        datasetId,
        orderBy: order,
        orderByDescending: desc,
        page,
        rowsPerPage: rows,
        searchText: text,
    })

    const {
        data: totalRowCount,
        error: countError,
        refetch: refetchRowCount,
        isError: isCountError,
        isFetching: isCountFetching,
    } = useFetchDatasetRowCountQuery({ datasetId, searchText: text })

    const maxPage = Math.ceil((totalRowCount ?? 0) / rows) - 1
    const hasNextPage = page < maxPage
    const hasPrevPage = page > 0

    // keep next page in cache
    useFetchDatasetPropertiesQuery(
        {
            datasetId,
            orderBy: order,
            orderByDescending: desc,
            page: page + 1,
            rowsPerPage: rows,
            searchText: text,
        },
        { skip: !hasNextPage },
    )

    // keep previous page in cache
    useFetchDatasetPropertiesQuery(
        {
            datasetId,
            orderBy: order,
            orderByDescending: desc,
            page: page - 1,
            rowsPerPage: rows,
            searchText: text,
        },
        { skip: !hasPrevPage },
    )

    const infoboxToUse = useInfoboxToUse(datasetId)
    const isPublic: boolean = useAppSelector(getSelectedAppIsPublic)

    const { data: dprDatasetColumns = null } = useFetchDprDatasetColumnsQuery(
        { datasetId, isPublic },
        { skip: infoboxToUse !== "dpr" },
    )

    const onOrderBy = (column: string) => {
        if (column === order) {
            setPage(0)
            setDesc(!desc)
        } else {
            setOrder(column)
            setPage(0)
            setDesc(false)
        }
    }

    const onSearch = (searchText: string) => {
        setText(searchText)
        setPage(0)
    }

    const progressColumn = dprDatasetColumns?.progressColumn.prettyName ?? ""
    const latestCompletedColumn = dprDatasetColumns?.latestCompletedColumn.prettyName ?? ""

    const atributeTableData =
        !!data && !!dprDatasetColumns
            ? ({
                  properties: data.properties?.map(x => {
                      const { [latestCompletedColumn]: _1, [progressColumn]: _2, ...rest } = x
                      return rest
                  }),
              } as GetDatasetPropertiesResult)
            : data

    return {
        data: atributeTableData,
        error: error ?? countError,
        orderBy: order,
        orderByDesc: desc,
        refetch: () => {
            refetch()
            refetchRowCount()
        },
        rowsPerPage: rows,
        searchText: text,
        tablePage: page,
        totalRowCount,
        isError: isError || isCountError,
        isFetching: isFetching || isCountFetching,
        setOrderBy: onOrderBy,
        setRowsPerPage: (rowsPerPage: number) => setRows(rowsPerPage),
        setSearchText: onSearch,
        setTablePage: (tablePage: number) => setPage(tablePage),
    }
}

export default useAttributeTableData
