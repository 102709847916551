import { useEffect } from "react"
import { CustomTypography } from "@windgis/shared"
import { useHistory, useParams } from "react-router-dom"
import Header from "app/components/Header/Header"
import HeaderButtons from "app/components/HeaderButtons/HeaderButtons"
import ProjectsPicker from "features/core/components/ProjectsPicker/ProjectsPicker"
import { HealthEnum } from "features/health/model/HealthEnum"
import { getHealth } from "features/health/selectors"
import { setHealth } from "features/health/slice"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import { useAppSelector } from "store/hooks/useAppSelector"
import { useStyles } from "./styles"

type Params = {
    clientId: string
    projectId: string
}

export enum ErrorTypes {
    Unexpected,
    NoResources,
    NoPermission,
    PendingUser,
}

type HistoryState = { errorType?: ErrorTypes }

const errorMessage = {
    [ErrorTypes.NoPermission]: "You do not have permission to access this project",
    [ErrorTypes.NoResources]: "Your resources have not yet been created",
    [ErrorTypes.PendingUser]: "You have no role assigned in the WindGIS system",
    [ErrorTypes.Unexpected]: "An unexpected error occurred",
}

const ErrorView = () => {
    const classes = useStyles()
    const { clientId, projectId } = useParams<Params>()
    const health = useAppSelector(getHealth)
    const dispatch = useAppDispatch()
    const history = useHistory<HistoryState>()

    const errorType = history.location.state?.errorType ?? ErrorTypes.Unexpected

    useEffect(() => {
        if (health === HealthEnum.Unknown) {
            history.push(`/${clientId}/${projectId}`)
        }

        return () => {
            dispatch(setHealth(HealthEnum.Unknown))
        }
    }, [health])

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Header />
                <HeaderButtons />
            </div>
            <div className={`${classes.errorContainer}`}>
                <CustomTypography color="inherit" textWeight="semibold" variant="h1">
                    Error
                </CustomTypography>
                <CustomTypography color="inherit" textWeight="semibold" variant="h2">
                    {errorMessage[errorType]}
                </CustomTypography>
                <CustomTypography color="inherit" textWeight="semibold" variant="h6">
                    Please contact an administrator
                </CustomTypography>
            </div>
        </div>
    )
}

export default ErrorView
