import { useEffect } from "react"
import InfoIconOutlined from "@mui/icons-material/InfoOutlined"
import { Box, Divider, Stack, Typography } from "@mui/material"
import { availableURLSearchParams, useSearchParams, VirtualizedList } from "@windgis/shared"
import dayjs from "dayjs"
import AutoSizer from "react-virtualized-auto-sizer"
import { useMap } from "@emblautec/react-map-gl"
import {
    getAISSubscriptionInfo,
    getArchiveData,
    getBoatDetails,
    getSelectedArchiveSubscriptionId,
    getSelectedVesselHasNoData,
} from "selectors/aisSelectors"
import { useAppSelector } from "store/hooks/useAppSelector"
import { featureFlags } from "../../featureFlags"
import { useGetFeatureFlagForPublicAppQuery } from "../../featureFlags/api"
import { setAreAISPlaybackBoatsOnMap, setSelectedArchiveSubscriptionId } from "../../reducers/ais"
import { getSelectedAppIsPublic } from "../../selectors/appsSelectors"
import { getFeatureFlags } from "../../selectors/featureFlagsSelectors"
import { useAppDispatch } from "../../store/hooks/useAppDispatch"
import { shouldDisplayBoat } from "../map/components/DeckGL/utils"
import { StyledVesselsContainerStack, StyledWarningStack } from "./AisRoute.styles"
import { useFetchArchiveMetadataQuery } from "./api"
import { AisArchivePlayer, utils } from "./components/AisArchivePlayer"
import { PLAYER_WIDTH } from "./constants"
import AisSubscriptionCardContainer from "./containers/AisSubscriptionCardContainer"
import useLeftDistance from "./hooks/useLeftDistance"
import { BoatDetailsWithCoordinates } from "./models/BoatDetails"
import { BoatPositionWithStartTime } from "./models/BoatPositionWithStartTime"

function AisRoute() {
    const subscriptions = useAppSelector(getAISSubscriptionInfo)

    const dispatch = useAppDispatch()

    const selectedSubscriptionForAISPlayback = useAppSelector(getSelectedArchiveSubscriptionId)

    const leftDistance = useLeftDistance(PLAYER_WIDTH)

    const { AIS_ARCHIVE } = useAppSelector(getFeatureFlags)

    const archiveData = useAppSelector(getArchiveData)

    const boatDetails = useAppSelector(getBoatDetails)

    const selectedVesselHasNoData = useAppSelector(getSelectedVesselHasNoData)

    const isPublic = useAppSelector(getSelectedAppIsPublic)

    const { updateSearchParams } = useSearchParams()

    const { mainMap } = useMap()

    const previousBucketDictionary = Object.fromEntries(
        archiveData?.previousBucket?.boatPositions.map(bp => [utils.getBoatIdentifier(bp), bp]) ?? [],
    ) as Record<string, BoatPositionWithStartTime>

    const vesselsDictionaryPosition = Object.values(archiveData?.boatsDictionary ?? []).filter(boat =>
        shouldDisplayBoat(boat, previousBucketDictionary, archiveData?.currentBucket.startDate ?? dayjs()),
    )

    const vesselArchiveDetails: BoatDetailsWithCoordinates[] = vesselsDictionaryPosition.map(vessel => ({
        ...boatDetails[vessel.boatDetailsId],
        lat: vessel.lat,
        lon: vessel.lon,
    }))

    const { data: publicAisArchiveFeatureFlag } = useGetFeatureFlagForPublicAppQuery(
        { featureFlag: featureFlags.AIS_ARCHIVE },
        { skip: !isPublic },
    )

    const isArchiveFeatureEnabled = isPublic ? Boolean(publicAisArchiveFeatureFlag?.isEnabled) : AIS_ARCHIVE

    const { data: selectedArchiveMetadata, isFetching: isSelectedArchiveMetadataFetching } =
        useFetchArchiveMetadataQuery(selectedSubscriptionForAISPlayback ?? "", {
            skip: !selectedSubscriptionForAISPlayback || !isArchiveFeatureEnabled,
        })

    const handleActiveSubscriptionChange = (isActive: boolean, subscriptionId: string) => {
        if ((!isActive && subscriptionId === selectedSubscriptionForAISPlayback) || isActive) {
            const selectedSubscription = subscriptions.find(subscription => subscription.id === subscriptionId)
            dispatch(setSelectedArchiveSubscriptionId(isActive ? selectedSubscription?.id : undefined))

            if (!isActive) {
                dispatch(setAreAISPlaybackBoatsOnMap(false))
            }
        }
    }

    useEffect(() => {
        mainMap?.once("idle", () => {
            updateSearchParams({
                remove: [availableURLSearchParams.selectedVesselId.key, availableURLSearchParams.pausePlayback.key],
            })
        })
    }, [selectedSubscriptionForAISPlayback])

    return (
        <Stack>
            <Divider />

            <Stack paddingBlock={1.5} paddingInline={2}>
                <Typography fontWeight="bold">Vessel Position</Typography>
            </Stack>

            <Divider />

            <StyledVesselsContainerStack flexGrow={1} minHeight={0} overflow="auto" padding={2} paddingBottom={0}>
                <AutoSizer disableWidth={false}>
                    {({ height, width }) => (
                        <VirtualizedList height={height} width={width}>
                            {subscriptions.map(subscription => (
                                <Box key={subscription.id} marginBottom={2}>
                                    <AisSubscriptionCardContainer
                                        archiveVesselsDetails={vesselArchiveDetails.filter(
                                            vessel => vessel.subscriptionId === subscription.id,
                                        )}
                                        subscription={subscription}
                                        isArchiveFeatureEnabled={isArchiveFeatureEnabled}
                                        isArchiveMetadataLoading={isSelectedArchiveMetadataFetching}
                                        isArchivePlayerActive={selectedSubscriptionForAISPlayback === subscription.id}
                                        onArchivePlayerCheck={checked =>
                                            handleActiveSubscriptionChange(checked, subscription.id)
                                        }
                                    />
                                </Box>
                            ))}
                        </VirtualizedList>
                    )}
                </AutoSizer>
            </StyledVesselsContainerStack>

            {!!(selectedSubscriptionForAISPlayback && selectedArchiveMetadata) && (
                <Box bottom={60} left={`calc(50% + ${leftDistance}px)`} position="fixed" zIndex={2}>
                    {selectedVesselHasNoData && (
                        <StyledWarningStack zIndex={2}>
                            <InfoIconOutlined />
                            Some data is missing from this timelapse re-play.
                        </StyledWarningStack>
                    )}

                    <AisArchivePlayer
                        archiveMetadata={selectedArchiveMetadata}
                        subscription={subscriptions.filter(sub => sub.id === selectedSubscriptionForAISPlayback)[0]}
                        onClose={() => {
                            dispatch(setAreAISPlaybackBoatsOnMap(false))
                            dispatch(setSelectedArchiveSubscriptionId(undefined))
                        }}
                    />
                </Box>
            )}
        </Stack>
    )
}

export default AisRoute
