import { TableRow } from "@mui/material"
import { CustomTypography } from "@windgis/shared"
import dayjs from "dayjs"
import Linkify from "react-linkify"
import { Property } from "components/map/infoBoxes/hooks/useBuildProperties"
import { getRenamedPropertyKey, POSITION_RECEIVED_PROPERTY } from "components/sidebar/ais/utils"
import {
    StyledTableCellKey,
    StyledTableCellValue,
    NoDataBox,
} from "features/infobox/components/DefaultInfobox/DefaultInfobox.styles"
import { generatePropertyName, getRenderedPropertyValue } from "utils/infoBoxUtils"

type PropertiesDisplayProps = {
    properties: Property[]
}

const PropertiesDisplay = ({ properties }: PropertiesDisplayProps) => {
    if (!properties || properties.length === 0) {
        return <NoDataBox>No properties to display</NoDataBox>
    }

    return properties.map(([key, value], index) => {
        const formattedValue = key.includes("dato") ? dayjs().format("L") : value
        const displayKey = key.includes(POSITION_RECEIVED_PROPERTY)
            ? getRenamedPropertyKey(POSITION_RECEIVED_PROPERTY)
            : key

        return (
            <TableRow key={index}>
                <StyledTableCellKey>
                    <CustomTypography textWeight="bold" variant="body2">
                        {generatePropertyName(displayKey)}
                    </CustomTypography>
                </StyledTableCellKey>
                <StyledTableCellValue>
                    <CustomTypography variant="body2">
                        <Linkify
                            componentDecorator={(decoratedHref, _, key) => (
                                <a key={key} href={decoratedHref} rel="noreferrer" target="_blank">
                                    Link
                                </a>
                            )}
                        >
                            <>{getRenderedPropertyValue(formattedValue)}</>
                        </Linkify>
                    </CustomTypography>
                </StyledTableCellValue>
            </TableRow>
        )
    })
}

export default PropertiesDisplay
