import makeStyles from "@mui/styles/makeStyles"

const useAttributeTableHeaderStyles = makeStyles(theme => ({
    closeButton: {
        color: theme.customColors.white,
        position: "absolute",
        right: 0,
        top: 0,
    },
    header: {
        backgroundColor: theme.customColors.primaryColor,
        color: theme.customColors.white,
        padding: theme.spacing(1),
        position: "relative",
        textAlign: "center",
    },
    downloadButton: {
        color: theme.customColors.white,
    },
    downloadContainer: {
        alignItems: "center",
        display: "flex",
        position: "absolute",
        right: 50,
        top: 7,
    },
    downloadSelectedValue: {
        color: theme.customColors.white,
        marginRight: 5,
        marginTop: 2,
    },
}))

export default useAttributeTableHeaderStyles
