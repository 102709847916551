import React from "react"
import { CircularProgress, Grid } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { CustomTypography } from "@windgis/shared"

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: "rgb(245, 247, 248)",
        height: "100%",
        width: "100%",
    },
}))

const LoaderComponent = ({ text }) => {
    const classes = useStyles()

    return (
        <Grid alignItems="center" className={classes.root} container justifyContent="center">
            <Grid className={classes.loaderContainer} item xs={12}>
                <Grid
                    alignItems="center"
                    className={classes.container}
                    container
                    flexDirection={"column"}
                    justifyContent="center"
                >
                    <CircularProgress size={60} />
                    <CustomTypography variant="h6">{text}</CustomTypography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default LoaderComponent
