import { useState } from "react"
import { Close } from "@mui/icons-material"
import HistoryIcon from "@mui/icons-material/History"
import SettingsIcon from "@mui/icons-material/Settings"
import { Box, IconButton, Paper, SliderValueLabel, Stack, Typography } from "@mui/material"
import { Dayjs } from "dayjs"
import QuickRangePicker from "components/CustomDatePicker/QuickRangePicker"
import { PlaybackSpeed } from "../../models/PlaybackSpeed"
import AisSettingsModal from "./AisSettingsModal"
import ArchivePlayerControl from "./ArchivePlayerControl"
import PlaybackSpeedSelect from "./PlaybackSpeedSelect"

type Props = {
    endDate: Dayjs
    filteringInterval: [Dayjs, Dayjs]
    getLabelText: (value: number) => string
    label: string
    playbackSpeed: PlaybackSpeed
    sliderValue: number
    step: number
    subscriptionName: string
    isPlaying: boolean
    shouldShowReplay: boolean
    startDate: Dayjs
    onClose: () => void
    onFilteringIntervalChange: (newInterval: [Dayjs, Dayjs]) => void
    onPlaybackSpeedChange: (newValue: PlaybackSpeed) => void
    onPlayToggle: () => void
    onReplay: () => void
    onSliderValueChange: (newValue: number) => void
}

const AisArchivePlayer = ({
    endDate,
    filteringInterval,
    getLabelText,
    label,
    playbackSpeed,
    sliderValue,
    step,
    subscriptionName,
    isPlaying,
    shouldShowReplay,
    startDate,
    onClose,
    onFilteringIntervalChange,
    onPlaybackSpeedChange,
    onPlayToggle,
    onReplay,
    onSliderValueChange,
}: Props) => {
    const [openedSettings, setOpenedSettings] = useState(false)
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const onDateIntervalFilter = (newInterval: [Dayjs, Dayjs]) => {
        setOpenedSettings(false)
        onFilteringIntervalChange(newInterval)
    }

    const onClear = () => {
        onDateIntervalFilter([startDate, endDate])
    }

    return (
        <>
            <Paper>
                <Box padding={2} width={560}>
                    <Stack alignItems="center" direction="row" gap={1} justifyContent="space-between">
                        <Stack direction="row" gap={1}>
                            <HistoryIcon />
                            <Typography>History - {subscriptionName}</Typography>
                        </Stack>
                        <Stack alignItems="center" direction="row" gap={1}>
                            <Typography>{label}</Typography>
                            <IconButton onClick={onClose}>
                                <Close />
                            </IconButton>
                        </Stack>
                    </Stack>
                    <Box paddingTop={1}>
                        <Stack alignItems="center" direction="row" gap={1}>
                            <Box flexGrow={1}>
                                <ArchivePlayerControl
                                    getLabelText={getLabelText}
                                    step={step}
                                    value={sliderValue}
                                    isPlaying={isPlaying}
                                    shouldShowReplay={shouldShowReplay}
                                    onChange={onSliderValueChange}
                                    onPlayToggle={onPlayToggle}
                                    onReplay={onReplay}
                                />
                            </Box>
                            <PlaybackSpeedSelect speed={playbackSpeed} onChange={onPlaybackSpeedChange} />
                            <IconButton color="primary" onClick={() => setOpenedSettings(true)}>
                                <SettingsIcon />
                            </IconButton>
                        </Stack>
                    </Box>
                    <Stack alignItems="center" direction="row" paddingTop={1}>
                        <Typography>View history from: </Typography>
                        <QuickRangePicker
                            displayIcons={false}
                            flexDirection="row"
                            interval={filteringInterval}
                            maxDate={endDate}
                            minDate={startDate}
                            resetOnClick
                            onClear={onClear}
                            onSetInterval={onDateIntervalFilter}
                        />
                    </Stack>
                </Box>
            </Paper>

            <AisSettingsModal
                anchorEl={anchorEl}
                currentInterval={filteringInterval}
                endDate={endDate}
                open={openedSettings}
                startDate={startDate}
                handlePopoverClose={handlePopoverClose}
                handlePopoverOpen={handlePopoverOpen}
                onApply={newInterval => onDateIntervalFilter(newInterval)}
                onClose={() => {
                    setOpenedSettings(false)
                }}
            />
        </>
    )
}
export default AisArchivePlayer
